<div *ngIf="form; else loadingForm" [ngSwitch]="state">
  <ng-container *ngSwitchCase="STATES.Verification">
    <ng-container *ngTemplateOutlet="verificationTemplate; context: { $implicit: 'verification' }"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="STATES.MainForm">
    <ng-container *ngTemplateOutlet="formTemplate; context: { $implicit: 'main' }"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="STATES.FollowForm">
    <ng-container *ngTemplateOutlet="formTemplate; context: { $implicit: 'follow' }"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="STATES.PrePopulated">
    <ng-container *ngTemplateOutlet="prepopulatedTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="STATES.Payment">
    <ng-container *ngTemplateOutlet="paymentTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="STATES.ThankYou">
    <ng-container *ngTemplateOutlet="thankYouTemplate"></ng-container>
  </ng-container>
</div>

<ng-template #loadingForm>
  <div editable="form" fxLayout fxLayoutAlign="center center" class="spinner-container">
    <ng-container *ngTemplateOutlet="spinner"></ng-container>
  </div>
</ng-template>

<ng-template #verificationTemplate>
  <elm-verification-form
    [config]="verificationForm"
    [formGuid]="form.guid"
    (confirm)="onUserVerified($event)"
  ></elm-verification-form>
</ng-template>

<ng-template #formTemplate let-formType>
  <div editable="form">
    <p
      *ngIf="row.form.showHeaderText"
      class="form-header"
      [innerHTML]="row.form.headerText"
      [style.color]="row.form.headerTextColor"
    ></p>
    <lum-df-form #dynamicForm [form]="form | elmFormFields: formType" [data]="formData[formType] || []">
      <div>
        <ng-container *ngIf="(isSubmitting$ | async) === false; else spinner">
          <button
            *ngIf="row.formButton; let button"
            editable="formButton"
            elmPgBtn
            [style.width.%]="button.isFullWidth ? '100' : null"
            [styling]="button.styling"
            [buttonColor]="button.buttonColor"
            [buttonHoverColor]="button.buttonHoverColor"
            [labelColor]="button.labelColor"
            [labelHoverColor]="button.labelHoverColor"
            [size]="button.size"
            [shape]="button.shape"
            [hasBoxShadow]="button.hasBoxShadow"
            [ariaLabel]="button.ariaLabel"
            class="form-submit-btn"
            type="submit"
            elmRecaptchaTrigger
            [theme]="recaptchaTheme"
            (click)="dynamicForm.valid ? recaptchaTrigger.verify() : onInvalidSubmit()"
            (recaptchaVerified)="onSubmit(dynamicForm)"
            (recaptchaFailed)="botDetected.emit()"
            #recaptchaTrigger="elmRecaptchaTrigger"
          >
            {{ recaptchaTrigger.verifying ? ('page451Rows.formStateMachine.verifying' | transloco) : button.label }}
          </button>
        </ng-container>
      </div>
    </lum-df-form>
  </div>
</ng-template>

<!--
    PREPOPULATED STATE
-->
<ng-template #prepopulatedTemplate>
  <elm-pg-prepopulate-form
    [fields]="prepopulatedData"
    [thankYouMode]="false"
    [title]="row.titlePrepopulate"
    [subtitle]="row.subtitlePrepopulate"
    [theme]="row.form.theme"
    [background]="null"
    [padding]="null"
  >
    <button
      elmPgBtn
      *ngIf="row.formButton; let button"
      prepopulate-edit-button
      styling="outline"
      [buttonColor]="editButtonColorByTheme"
      [buttonHoverColor]="editButtonColorByTheme"
      [labelColor]="editButtonLabelColorByTheme"
      [labelHoverColor]="editButtonLabelColorByTheme"
      [shape]="button.shape"
      [size]="button.size"
      [hasBoxShadow]="false"
      (click)="prepopulatedEdit.emit(row.pageGuid)"
      [ariaLabel]="button.ariaLabel"
    >
      {{ row.buttonPrepopulateEditText || 'Edit' }}
    </button>

    <button
      elmPgBtn
      *ngIf="row.formButton; let button"
      prepopulate-submit-button
      [styling]="button.styling"
      [buttonColor]="button.buttonColor"
      [buttonHoverColor]="button.buttonHoverColor"
      [labelColor]="button.labelColor"
      [labelHoverColor]="button.labelHoverColor"
      [shape]="button.shape"
      [size]="button.size"
      [hasBoxShadow]="false"
      (click)="prepopulatedSubmit.emit(row.pageGuid)"
      [ariaLabel]="button.ariaLabel"
    >
      {{ row.buttonPrepopulateSubmitText || 'Submit' }}
    </button>
  </elm-pg-prepopulate-form>
</ng-template>

<!--
    PAYMENT STATE
-->
<ng-template #paymentTemplate>
  <ng-container [ngSwitch]="!!user && !!form?.payment">
    <elm-page-payment
      *ngSwitchCase="true"
      [form]="form"
      [user]="user"
      (paymentDone)="onPaymentDone($event)"
      (paymentSkipped)="paymentDone.emit()"
    ></elm-page-payment>
    <h5 *ngSwitchCase="false">There was an error loading payment form, please try again.</h5>
  </ng-container>
</ng-template>

<!--
    THANK YOU STATE
-->
<ng-template #thankYouTemplate>
  <elm-pg-prepopulate-form
    [thankYouMode]="true"
    [title]="thankYou.title || 'Thank You'"
    [subtitle]="thankYou.text"
    [theme]="row.form.theme"
    [background]="null"
    [padding]="null"
  >
    <img *ngIf="thankYou.download_preview_file_data as downloadFile" [src]="downloadFile.url" />

    <a
      elmPgBtn
      *ngIf="thankYou.show_button && row.formButton; let button"
      prepopulate-submit-button
      [styling]="button.styling"
      [buttonColor]="button.buttonColor"
      [buttonHoverColor]="button.buttonHoverColor"
      [labelColor]="button.labelColor"
      [labelHoverColor]="button.labelHoverColor"
      [size]="button.size"
      [shape]="button.shape"
      [hasBoxShadow]="button.hasBoxShadow"
      [href]="thankYouActionUrl"
      [ariaLabel]="button.ariaLabel"
    >
      {{ thankYou.button_text }}
    </a>
  </elm-pg-prepopulate-form>
  <elm-payment-summary *ngIf="summary" [summary]="summary"></elm-payment-summary>
</ng-template>

<ng-template #spinner>
  <div class="spinner-center">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>
