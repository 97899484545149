<div [formGroup]="form" fxLayout="row wrap" *transloco="let t; read: 'elmCreditCardForm'">
  <div fxFlex="100">
    <mat-form-field class="elm-block">
      <mat-label>{{ t('name.label') }}</mat-label>
      <input matInput (change)="updateModel()" formControlName="name" required />
      <mat-error align="end" *ngIf="nameField.hasError('required')">{{ t('name.errors.required') }}</mat-error>
    </mat-form-field>
  </div>

  <div fxFlex="100">
    <mat-form-field class="elm-block">
      <mat-label>{{ t('number.label') }}</mat-label>
      <input matInput formControlName="number" (change)="updateModel()" [mask]="CREDIT_CARD_MASK" required />
      <mat-error *ngIf="numberField.hasError('required')">{{ t('number.errors.required') }}</mat-error>
      <mat-error
        class="mat-error"
        *ngIf="!numberField.hasError('required') && numberField.hasError('invalidCreditCard')"
      >
        {{ t('number.errors.invalidCreditCard') }}
      </mat-error>
      <img
        matSuffix
        *ngIf="creditCardImage$ | async as creditCardImage"
        class="cc-image"
        src="assets/images/cc/{{ creditCardImage }}"
        [alt]="t('number.image')"
      />
    </mat-form-field>
  </div>

  <div fxFlex="100" fxLayout fxLayoutGap="16px">
    <div fxFlex="calc(66.6666% - 8px)" fxFlex.xs="100">
      <mat-form-field class="elm-block">
        <mat-label>{{ t('expiryDate.label') }}</mat-label>
        <input
          formControlName="expiryDate"
          (change)="updateModel()"
          matInput
          [mask]="EXPIRY_DATE_MASK"
          [dropSpecialCharacters]="true"
          required
        />
        <mat-error align="end" *ngIf="expiryDateField.hasError('invalidExpiryDate')">{{
          t('expiryDate.errors.invalidExpiryDate')
        }}</mat-error>
        <mat-error align="end" *ngIf="expiryDateField.hasError('required')">{{
          t('expiryDate.errors.required')
        }}</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="calc(33.3333% - 8px)" fxFlex.xs="100">
      <mat-form-field class="elm-block">
        <mat-label>{{ t('cvc.label') }}</mat-label>
        <input formControlName="cvc" (change)="updateModel()" type="text" matInput [mask]="CVC_MASK" required />
        <img
          matSuffix
          *ngIf="cvcImage$ | async as cvcImage"
          class="cvc-image"
          src="assets/images/cvc/{{ cvcImage }}"
          [alt]="t('cvc.label')"
        />
        <mat-error align="end" *ngIf="cvcField.hasError('required')">{{ t('cvc.errors.required') }}</mat-error>
        <mat-error align="end" *ngIf="cvcField.hasError('pattern')">{{ t('cvc.errors.pattern') }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
