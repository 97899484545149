import {
  Task as Subtask,
  TasksApi_GetSubtasksQuery as GetSubtasks,
  TasksApi_GetSubtasksQueryVariables as GetSubtasksVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { Subtask, GetSubtasks, GetSubtasksVariables };

export const getSubtasks = gql`
  query TasksApi_GetSubtasks($parentGuid: String!) {
    subtasks(input: { parentGuid: $parentGuid }) {
      guid
      name
      due_datetime
      type_guid
      status_guid
      status
      priority_guid
      related_to
      assigned_to
      description
      parent_task_guid
      created_by
      created_at
      updated_at
    }
  }
`;
