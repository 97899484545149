import { ApplicationsApi } from '@element451-libs/models451';
import { compareDescending } from '@element451-libs/utils451/dates';
import { sortCollection } from '@element451-libs/utils451/helpers';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { head } from 'lodash';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { selectApp } from '../app.feature';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import { USER_APPLICATIONS_ACTIONS } from '../user-applications/user-applications.actions';
import { CHECKLIST_ACTIONS, ChecklistAction } from './checklist.actions';

export interface ChecklistState
  extends EntityState<ApplicationsApi.ChecklistItem> {
  loading: boolean;
  loaded: boolean;
  registrationId: string;
}

const selectId = (checklistItem: ApplicationsApi.ChecklistItem) =>
  checklistItem._id;

const adapter = createEntityAdapter<ApplicationsApi.ChecklistItem>({
  selectId: selectId,
  sortComparer: false
});

const initialState = adapter.getInitialState({
  loading: false,
  loaded: false,
  registrationId: null
});

export const checklistFeature = 'checklist';

export function checklistReducer(
  state: ChecklistState = initialState,
  action:
    | ChecklistAction
    | fromAccount.AccountAction
    | fromDashboard.DashboardAction
    | fromUserApplications.UserApplicationsAction
): ChecklistState {
  switch (action.type) {
    case CHECKLIST_ACTIONS.LOAD_CHECKLIST_REQUEST: {
      return state.loaded ? state : { ...state, loading: true };
    }

    case CHECKLIST_ACTIONS.LOAD_CHECKLIST_SUCCESS: {
      state = adapter.setAll(action.payload, state);
      return {
        ...state,
        loaded: true,
        loading: false
      };
    }

    case CHECKLIST_ACTIONS.LOAD_CHECKLIST_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      };

    case DASHBOARD_ACTIONS.SWITCH_APPLICATION:
    case ACCOUNT_ACTIONS.SIGN_OUT:
    case USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION:
      return { ...initialState };

    default:
      return state;
  }
}

const _selectChecklistState =
  createFeatureSelector<ChecklistState>(checklistFeature);

export const selectChecklistState = createSelector(
  selectApp,
  _selectChecklistState
);

export const selectLoading = createSelector(
  selectChecklistState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectChecklistState,
  state => state.loaded
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectChecklistState);

function notCompletedItems(items: ApplicationsApi.ChecklistItem[]) {
  return items.filter(
    item => !isChecklistItemCompleted(item) && !isChecklistItemWaived(item)
  );
}

export function isChecklistItemCompleted(item: ApplicationsApi.ChecklistItem) {
  return item.status === ApplicationsApi.ChecklistItemStatus.Completed;
}

export function isChecklistItemWaived(item: ApplicationsApi.ChecklistItem) {
  return item.status === ApplicationsApi.ChecklistItemStatus.Waived;
}

function completedItems(items: ApplicationsApi.ChecklistItem[]) {
  return items.filter(isChecklistItemCompleted);
}

function waivedItems(items: ApplicationsApi.ChecklistItem[]) {
  return items.filter(isChecklistItemWaived);
}

function requiredItems(items: ApplicationsApi.ChecklistItem[]) {
  return items.filter(item => item.required);
}

function optionalItems(items: ApplicationsApi.ChecklistItem[]) {
  return items.filter(item => !item.required);
}

export const hasChecklist = createSelector(selectAll, all => all.length > 0);

export const selectRequiredItems = createSelector(selectAll, requiredItems);

export const selectOptionalItems = createSelector(selectAll, optionalItems);

export const selectCompletedItems = createSelector(selectAll, completedItems);

export const selectWaivedItems = createSelector(selectAll, waivedItems);

export const selectNotCompletedItems = createSelector(
  selectAll,
  notCompletedItems
);

export const selectCalculatePercent = createSelector(
  selectAll,
  selectCompletedItems,
  selectWaivedItems,
  (all, completed, waived) =>
    Math.round(((completed.length + waived.length) / all.length) * 100)
);

const sortChecklistItems = sortCollection<ApplicationsApi.ChecklistItem>(
  (a, b) => compareDescending(a.status_changed_at, b.status_changed_at)
);

export const selectLastChecklistUpdate = createSelector(selectAll, items => {
  const sorted = sortChecklistItems(items);
  const lastUpdated = head(sorted);
  return lastUpdated ? lastUpdated.status_changed_at : null;
});

export const selectNotCompletedRequiredItems = createSelector(
  selectRequiredItems,
  notCompletedItems
);

export const selectNotCompletedOptionalItems = createSelector(
  selectOptionalItems,
  notCompletedItems
);

export const selectIsChecklistNotCompleted = createSelector(
  selectNotCompletedRequiredItems,
  items => items.length > 0
);
