export enum PhoneType {
  Cell = 'cell',
  Home = 'home'
}

export enum PhoneValidity {
  Invalid = 'invalid',
  InvalidFormatted = 'invalid-formatted',
  ValidFree = 'valid-free',
  ValidPaid = 'valid-paid',
  Voip = 'voip'
}

export interface Phone {
  type?: PhoneType;
  country_alpha_2?: string; // 'CA';
  country_code?: string; // '1';
  number?: string; // '123456789';
  index_weight?: number;
  valid?: boolean;
  formatted?: boolean;
  carrier_name?: string;
  carrier_type?: string;
  international?: string;
  default?: boolean;
  lookup_type?: string;
}

export const getPhone = (phones: Phone[], type: PhoneType) =>
  phones && phones.find(phone => phone.type === type);

export const getPhoneNumber = (phone: Phone) =>
  phone.international || `+${phone.country_code || ''}${phone.number || ''}`;

export function getPhoneValidity(phone: Phone) {
  if (phone.carrier_type === 'voip') {
    return PhoneValidity.Voip;
  }
  if (phone.valid && phone.lookup_type === 'regular') {
    return PhoneValidity.ValidFree;
  }
  switch (true) {
    case !phone.valid:
      return PhoneValidity.Invalid;
    case !phone.valid && phone.formatted:
      return PhoneValidity.InvalidFormatted;
    case phone.valid &&
      phone.formatted &&
      (phone.carrier_name === null || phone.carrier_name === undefined):
      return PhoneValidity.ValidFree;
    case phone.valid &&
      phone.formatted &&
      phone.carrier_name !== null &&
      phone.carrier_name !== undefined:
      return PhoneValidity.ValidPaid;
    default:
      return PhoneValidity.Invalid;
  }
}

export function stringifyPhone(phone: Phone) {
  const parts = [phone.country_code?.replace('+', ''), phone.number].filter(
    Boolean
  );
  return parts.length > 0 ? `${parts.join('')}` : '';
}
