import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { ElmDialogComponent } from './elm-dialog.component';
import { ELM_DIALOG_DIRECTIVES } from './elm-dialog.directives';
import { ElmDialogService } from './elm-dialog.service';

@NgModule({
  imports: [CommonModule, RouterModule, MatDialogModule, MatProgressBarModule],
  exports: [ElmDialogComponent, MatDialogModule, ELM_DIALOG_DIRECTIVES],
  declarations: [ElmDialogComponent, ELM_DIALOG_DIRECTIVES],
  providers: [ElmDialogService]
})
export class ElmDialogModule {}
