import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Campaigns451Api } from '@element451-libs/models451';
import { isEmpty, pick } from 'lodash';
import { Observable, concat } from 'rxjs';
import { last } from 'rxjs/operators';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { FoldersManager, FoldersManagerFactory } from '../folders';
import { responseData } from '../rxjs';
import {
  Api451Done,
  CollectionSearch,
  ElmMetadata2,
  ElmResponse,
  searchToParams,
  toEncodedItem,
  toItem,
  urlEncodedHeaders
} from '../shared';
import { TagsManager, TagsManagerFactory } from '../tags';

// shorthand
type R<T> = ElmResponse<T>;

export interface CommunicationsResponse
  extends ElmResponse<Campaigns451Api.Communication[]> {
  metadata: {
    resultset: ElmMetadata2;
  };
}

interface AnalyticsResponse extends ElmResponse<Campaigns451Api.Analytic[]> {
  metadata: {
    resultset: ElmMetadata2;
  };
}

interface TemplateDeleted {
  deleted: number;
  toBeDeleted: number;
}

interface TemplatePreview {
  categories_available: string[];
  email: string;
}

interface ResourceManagers<T> {
  communications: T;
  templates: T;
}

interface Content {
  sms: Campaigns451Api.SmsContent;
  email: Partial<Campaigns451Api.EmailContent>;
}

interface VariateContent {
  variate_content: Partial<Campaigns451Api.VariateContentExpanded>;
}

@Injectable()
export class Campaigns451ApiService {
  readonly communicationsResourcesRoute = 'campaigns/communications';
  readonly templatesResourcesRoute = 'campaigns/templates';

  readonly foldersManager: ResourceManagers<FoldersManager>;
  readonly tagsManager: ResourceManagers<
    TagsManager<Campaigns451Api.Communication | Campaigns451Api.Template>
  >;

  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    foldersAdapterFactory: FoldersManagerFactory,
    tagsManagerFactory: TagsManagerFactory<
      Campaigns451Api.Communication | Campaigns451Api.Template
    >
  ) {
    this.foldersManager = {
      communications: foldersAdapterFactory.makeManager(
        this.communicationsResourcesRoute
      ),
      templates: foldersAdapterFactory.makeManager(this.templatesResourcesRoute)
    };

    this.tagsManager = {
      communications: tagsManagerFactory.makeManager(
        this.communicationsResourcesRoute
      ),
      templates: tagsManagerFactory.makeManager(this.templatesResourcesRoute)
    };
  }

  getPreview(
    previewKey: string,
    categories?: Campaigns451Api.PreviewCategories
  ) {
    return this.http.post<R<Campaigns451Api.Preview>>(
      this.url(`campaigns/communications/${previewKey}/open_preview`),
      toItem({ categories })
    );
  }

  getPreviewForUser(guid: string, userId: string) {
    return this.http.post<R<Campaigns451Api.EmailPreviewForUser>>(
      this.url(`campaigns/communications/${guid}/preview/${userId}`),
      null
    );
  }

  searchTemplates({
    q,
    offset,
    limit,
    sort,
    filters,
    folder
  }: CollectionSearch<Campaigns451Api.Template> = {}) {
    let params = searchToParams({ q, offset, limit, sort });

    if (folder) {
      params = params.set('filter[folder]', folder);
    }

    if (filters) {
      Object.keys(filters).forEach(key => {
        filters[key].forEach(filter => {
          params = params.has(`filter[${key}][]`)
            ? params.append(`filter[${key}][]`, filter)
            : params.set(`filter[${key}][]`, filter);
        });
      });
    }

    return this.http.get<R<Campaigns451Api.TemplatesResponse[]>>(
      this.url(`campaigns/templates/list/saved`),
      { params }
    );
  }

  getTemplatePreview(guid: string) {
    return this.http.post<R<TemplatePreview>>(
      this.url(`campaigns/templates/${guid}/preview`),
      null
    );
  }

  getTemplate(guid: string) {
    return this.http.get<R<Campaigns451Api.TemplateExpanded>>(
      this.url(`campaigns/templates/${guid}`)
    );
  }

  createTemplate(template: Partial<Campaigns451Api.TemplateExpanded>) {
    return this.http.post<R<Campaigns451Api.TemplateExpanded>>(
      this.url(`campaigns/templates`),
      toItem(template)
    );
  }

  updateTemplate(
    guid: string,
    template: Partial<Campaigns451Api.TemplateExpanded>
  ) {
    return this.http.put<R<Campaigns451Api.TemplateExpanded>>(
      this.url(`campaigns/templates/${guid}`),
      toItem(template)
    );
  }

  duplicateTemplate(guid: string) {
    return this.http.post<R<Campaigns451Api.TemplatesResponse>>(
      this.url(`campaigns/templates/${guid}/replicate`),
      null
    );
  }

  deleteTemplate(guid: string) {
    return this.http.delete<R<TemplateDeleted>>(
      this.url(`campaigns/templates/${guid}`)
    );
  }

  getCommunications({
    q,
    offset,
    limit,
    sort,
    filters,
    folder,
    audience,
    type,
    status
  }: CollectionSearch<Campaigns451Api.Communication> = {}) {
    const params = searchToParams({ offset, limit, sort });

    let item = filters ? { q, ...filters } : { q };

    if (folder) {
      item = { ...item, folder };
    }

    return this.http.post<CommunicationsResponse>(
      this.url(`campaigns/communications/search?${params.toString()}`),
      toItem({ ...item, audience, type, status })
    );
  }

  getCommunication(guid: string) {
    return this.http.get<R<Campaigns451Api.CommunicationExpanded>>(
      this.url(`campaigns/communications/${guid}`)
    );
  }

  updateUtmParams(guid: string, utm_params: Campaigns451Api.UtmParams) {
    return this.http.put<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/utm`),
      toItem({
        utm_params
      })
    );
  }

  updateCommunication(
    guid: string,
    communication: Partial<Campaigns451Api.CommunicationExpanded>
  ) {
    const requests$: Observable<any>[] = [];

    const {
      channels,
      type,
      audience,
      content,
      dynamic_content,
      variate_content
    } = communication;

    const settingsUpdate = pick(communication, [
      'channels',
      'type',
      'settings',
      'priority',
      'tags'
    ]);

    if (!isEmpty(settingsUpdate)) {
      const settings$ = this.updateSettings(guid, settingsUpdate);
      requests$.push(settings$);
    }

    if (audience) {
      const audience$ = this.updateAudience(guid, { audience });
      requests$.push(audience$);
    }

    if (type === Campaigns451Api.CommunicationType.Dynamic && dynamic_content) {
      const dynamic$ = this.updateContent(guid, {
        dynamic_content
      });
      requests$.push(dynamic$);
    } else if (
      type === Campaigns451Api.CommunicationType.Multivariate &&
      variate_content
    ) {
      const variants$ = this.updateContent(guid, {
        variate_content
      });
      requests$.push(variants$);
    }

    const contentPayload: any = {};
    if (channels?.email && content?.email) {
      contentPayload.email = content.email;
    }
    if (channels?.sms && content?.sms) {
      contentPayload.sms = content.sms;
    }
    if (!isEmpty(contentPayload)) {
      const content$ = this.updateContent(guid, contentPayload);
      requests$.push(content$);
    }

    return concat(...requests$).pipe(last());
  }

  updateSettings(
    guid: string,
    communication: Partial<
      Pick<
        Campaigns451Api.CommunicationExpanded,
        'channels' | 'type' | 'settings' | 'priority' | 'tags'
      >
    >
  ) {
    return this.http.put<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}`),
      toItem(communication)
    );
  }

  updateAudience(
    guid: string,
    audience: Pick<Campaigns451Api.CommunicationExpanded, 'audience'>
  ) {
    return this.http.put<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/audience`),
      toItem(audience)
    );
  }

  updateContent(
    guid: string,
    content:
      | Partial<Pick<Campaigns451Api.CommunicationExpanded, 'dynamic_content'>>
      | Partial<Content>
      | VariateContent
  ) {
    return this.http.put<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/content`),
      toItem(content)
    );
  }

  duplicateCommunication(guid: string) {
    return this.http.post<R<Campaigns451Api.Communication>>(
      this.url(`campaigns/communications/${guid}/replicate`),
      null
    );
  }

  deleteCommunication(guid: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}`)
    );
  }

  validateCommunication(guid: string) {
    return this.http.get<R<Campaigns451Api.ValidationResponse>>(
      this.url(`campaigns/communications/${guid}/validate`)
    );
  }

  testCommunication(
    guid: string,
    payload: Campaigns451Api.CommunicationPreviewPayload
  ) {
    const formData = new FormData();
    formData.append('item', JSON.stringify(payload));
    return this.http.post<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/test`),
      formData
    );
  }

  createCommunication(payload: Campaigns451Api.CreateCommunicationPayload) {
    const formData = new FormData();
    formData.append('item', JSON.stringify(payload));

    return this.http.post<R<Campaigns451Api.CommunicationExpanded>>(
      this.url(`campaigns/communications`),
      formData
    );
  }

  createCondition(guid: string, condition: Campaigns451Api.ConditionPayload) {
    return this.http.post<R<Campaigns451Api.Condition>>(
      this.url(`campaigns/communications/${guid}/conditions`),
      toEncodedItem(condition),
      urlEncodedHeaders
    );
  }

  updateCondition(
    guid: string,
    conditionGuid: string,
    payload: Partial<Campaigns451Api.Condition>
  ) {
    return this.http.put<R<Campaigns451Api.Condition>>(
      this.url(`campaigns/communications/${guid}/conditions/${conditionGuid}`),
      toEncodedItem(payload),
      urlEncodedHeaders
    );
  }

  deleteCondition(guid: string, conditionGuid: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/conditions/${conditionGuid}`)
    );
  }

  // Analytics
  searchAnalytics({
    q,
    offset,
    limit,
    sort,
    filters
  }: CollectionSearch<R<Campaigns451Api.Analytic>> = {}) {
    const params = searchToParams({ offset, limit, sort });

    const item = filters ? { q, ...filters } : { q };

    const formData = new FormData();
    formData.append('item', JSON.stringify(item));

    return this.http.post<AnalyticsResponse>(
      this.url(
        `campaigns/communications/analytics/search?${params.toString()}`
      ),
      formData
    );
  }

  getAnalytics(guid: string) {
    return this.http.get<R<Campaigns451Api.Analytic>>(
      this.url(`campaigns/communications/${guid}/analytics`)
    );
  }

  getRecipients(guid: string) {
    return this.http.get<R<Campaigns451Api.Recipients[]>>(
      this.url(`campaigns/communications/${guid}/analytics/recipients`)
    );
  }

  getEmailOpens<T>(guid: string, type: Campaigns451Api.EmailOpensBy) {
    return this.http.post<R<T[]>>(
      this.url(`campaigns/communications/${guid}/analytics/opens/email`),
      toItem({
        pivot: [type, 'variant'],
        limit: null,
        sort: 'desc'
      })
    );
  }

  getEmailOpensBySoftware(guid: string) {
    return this.getEmailOpens<Campaigns451Api.SoftwareEmailOpens>(
      guid,
      Campaigns451Api.EmailOpensBy.Software
    );
  }

  getEmailOpensByDevice(guid: string) {
    return this.getEmailOpens<Campaigns451Api.DeviceEmailOpens>(
      guid,
      Campaigns451Api.EmailOpensBy.Device
    );
  }

  getBasicTemplates({
    limit,
    offset,
    sort
  }: CollectionSearch<Campaigns451Api.Template> = {}) {
    const params = searchToParams({ offset, limit, sort });
    return this.http.get<R<Campaigns451Api.TemplatesResponse[]>>(
      this.url(`campaigns/templates/list/basic`),
      { params }
    );
  }

  sendCommunication(
    guid: string,
    sendSettings: { send_settings: Campaigns451Api.SendSettings }
  ) {
    return this.http.put<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/send`),
      toItem(sendSettings)
    );
  }

  stopScheduledCommunication(guid: string) {
    return this.http.post<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/stop`),
      null
    );
  }

  sendCommuncationToOneUser(
    guid: string,
    userId: string,
    channels: ('email' | 'sms')[] = ['email', 'sms']
  ) {
    return this.http.post<R<Api451Done>>(
      this.url(`campaigns/communications/${guid}/singlesend`),
      {
        channels,
        target: {
          id: userId
        }
      }
    );
  }

  getCustomComponents() {
    return this.http
      .get<
        R<Campaigns451Api.CustomComponent[]>
      >(this.url('campaigns/emails/templates/components'))
      .pipe(responseData);
  }

  saveCustomComponent(data: { name: string; component: string }) {
    return this.http.post<R<Api451Done>>(
      this.url(`campaigns/emails/templates/components`),
      data
    );
  }

  updateCustomComponent(
    guid: string,
    data: { name: string; component: string }
  ) {
    return this.http.put<R<Api451Done>>(
      this.url(`campaigns/emails/templates/components/${guid}`),
      data
    );
  }

  deleteCustomComponent(guid: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`campaigns/emails/templates/components/${guid}`)
    );
  }
}
