import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ElmCreditCardFormComponent } from './elm-credit-card-form.component';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMaskDirective,
    TranslocoModule
  ],
  exports: [ElmCreditCardFormComponent],
  declarations: [ElmCreditCardFormComponent],
  providers: [provideNgxMask()]
})
export class ElmCreditCardFormModule {}
