import { Platform } from '@angular/cdk/platform';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import {
  browserTracingIntegration,
  captureException,
  init,
  replayIntegration,
  setContext,
  setUser
} from '@sentry/angular-ivy';
import { take } from 'rxjs/operators';
import { AccountService } from '../+state';
import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private config: ConfigService,
    private platform: Platform,
    private account: AccountService
  ) {
    init({
      dsn: environment.sentryDsn,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        replayIntegration()
      ],
      environment: environment.production ? 'production' : 'development',
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    });
    setContext('Client config', this.config.getClient());
  }

  private _setSentryContext() {
    this.account.account$.pipe(take(1)).subscribe(account => {
      if (account?.user?.id) {
        setUser({
          id: account.user.id,
          email: account.user.properties?.email
        });
      }
    });
  }

  /**
   * Method called for every value captured through the ErrorHandler
   */
  handleError(error: unknown): void {
    this._setSentryContext();

    const extractedError = this._extractError(error) || 'Handled unknown error';

    if (this.platform.isBrowser && this._isChunkLoadingError(extractedError)) {
      window.location.reload();
      return;
    }

    // Capture handled exception and send it to Sentry.
    if (environment.production) {
      this.zone.runOutsideAngular(() => captureException(extractedError));
    } else {
      console.error(extractedError);
    }
  }

  /**
   * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
   */
  protected _extractError(errorCandidate: unknown): unknown {
    let error = errorCandidate;

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }

  protected _isChunkLoadingError(error: any): boolean {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    return typeof error === 'string' && chunkFailedMessage.test(error);
  }
}
