<elm-dialog [loading]="loadingForm" *transloco="let t; read: 'submitDialog'">
  <elm-dialog-title class="elm-text-center">{{ t('title') }}</elm-dialog-title>
  <elm-dialog-description class="elm-text-center">{{ t('description') }}</elm-dialog-description>

  <elm-dialog-content>
    <elm-dialog-body>
      <lum-df-form [form]="form" [data]="data$ | async"></lum-df-form>
      <p *ngIf="!loadingForm && !loading && error">{{ error }}</p>
      <p *ngIf="isElectronicSignEmpty" class="lum-df-error">This field is required</p>
    </elm-dialog-body>
  </elm-dialog-content>

  <elm-dialog-actions>
    <button
      *ngIf="form"
      [attr.aria-label]="t('submitApplication')"
      class="elm-app451-btn-wide elm-dynamic-bg-primary elm-spacing-m"
      (click)="submit()"
    >
      <span class="elm-app451-btn-label-l">{{ t('submitApplication') }}</span>
    </button>

    <div *ngIf="loading"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>
  </elm-dialog-actions>
</elm-dialog>
