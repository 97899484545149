export enum UserActivityAction {
  EmailBounced = 'emailBounced',
  EmailClicked = 'emailClicked',
  EmailComplaint = 'emailComplaint',
  EmailDelivered = 'emailDelivered',
  EmailOpened = 'emailOpened',
  EmailUnsubscribed = 'emailUnsubscribed',
  SmsClicked = 'smsClicked',
  SmsDelivered = 'smsDelivered',
  SmsFailed = 'smsFailed',
  SmsUndelivered = 'smsUndelivered',
  UserCreated = 'userCreated',
  UserUpdated = 'userUpdated',
  UserLogin = 'userLogin',
  UserForgotPassword = 'userForgotPassword',
  AppRegistrationSuccessful = 'applicationRegistrationSuccessful',
  AppSubmitted = 'applicationSubmitted',
  AppCompleted = 'applicationCompleted',
  AppPaid = 'applicationPaid',
  AppDepositPaid = 'depositPaid',
  EventRegistrationSuccessful = 'eventRegistrationSuccessful',
  EventRegistrationUpdated = 'eventRegistrationUpdated',
  EventRegistrationCanceled = 'eventRegistrationCanceled',
  EventMarkedAttended = 'eventMarkedAttended',
  RecommendationRequested = 'recommendationRequested',
  RecommendationReceived = 'recommendationReceived',
  SurveyAnswered = 'surveyAnswered',
  PageView = 'pageview',
  LinkClick = 'linkClick',
  FormSubmitted = 'formSubmitted',
  FormPaid = 'formPaid',
  CustomActivityCompleted = 'externalActivityCompleted',
  UserMerged = 'userMerged',
  UserProfileUpdated = 'userProfileUpdated',
  UserProfileAddressUpdated = 'userProfileAddressUpdated',
  UserProfileEmailUpdated = 'userProfileEmailUpdated',
  UserProfileMilestonesUpdated = 'userProfileMilestonesUpdated',
  UserProfileNameUpdated = 'userProfileNameUpdated',
  UserTerritoryChanged = 'userTerritoryChanged',
  CourseEnrolled = 'courseEnrolled',
  CourseDropped = 'courseDropped',
  CourseUpdated = 'courseUpdated',
  CourseCompleted = 'courseCompleted',
  AppointmentScheduled = 'appointmentScheduled',
  AppointmentUpdated = 'appointmentUpdated',
  AppointmentCanceled = 'appointmentCanceled',
  AppointmentAttended = 'appointmentAttended',
  AppointmentNoShow = 'appointmentNoShow'
}

export enum AdminActivityAction {
  DecisionAssignmentUpdated = 'decisionAssignmentUpdated',
  DecisionScoreUpdated = 'decisionScoreUpdated',
  DecisionStatusUpdated = 'decisionStatusUpdated',
  DecisionStageUpdated = 'decisionStageUpdated',
  DecisionReleased = 'decisionReleased',
  DecisionEnteredCohort = 'decisionEnteredCohort',
  DecisionExitedCohort = 'decisionExitedCohort',
  DecisionNoteAdded = 'decisionNoteAdded',
  DecisionNoteUpdated = 'decisionNoteUpdated',
  DecisionNoteDeleted = 'decisionNoteDeleted',
  DecisionCriteriaUpdated = 'decisionCriteriaUpdated',
  DecisionCriteriaDeleted = 'decisionCriteriaDeleted',
  DecisionChecklistItemAdded = 'decisionChecklistItemAdded',
  DecisionChecklistItemUpdated = 'decisionChecklistItemUpdated',
  DecisionChecklistItemRemoved = 'decisionChecklistItemRemoved',
  DecisionChecklistCompleted = 'decisionChecklistCompleted',
  TaskAssigned = 'taskAssigned',
  TaskCreated = 'taskCreated',
  TaskDeleted = 'taskDeleted',
  TaskCompleted = 'taskCompleted',
  UserDeleted = 'userDeleted',
  UserProfileMilestoneUpdated = 'userProfileMilestoneUpdated',
  ConversationAddedParticipant = 'conversationAddedParticipant',
  ConversationAssignmentUpdated = 'conversationAssignmentUpdated',
  ConversationClosed = 'conversationClosed',
  ConversationDeleted = 'conversationDeleted',
  ConversationFavoriteUpdated = 'conversationFavoriteUpdated',
  ConversationJoined = 'conversationJoined',
  ConversationLeave = 'conversationLeave',
  ConversationOpened = 'conversationOpened',
  ConversationTagUpdated = 'conversationTagUpdated',
  PhoneCallBusy = 'phoneCallBusy',
  PhoneCallCancelled = 'phoneCallCancelled',
  PhoneCallCompleted = 'phoneCallCompleted',
  PhoneCallFailed = 'phoneCallFailed',
  PhoneCallNotAnswered = 'phoneCallNotAnswered'
}

export const ActivityAction = {
  ...UserActivityAction,
  ...AdminActivityAction
};

export type ActivityActionsUnion = UserActivityAction | AdminActivityAction;

export type ActivityActions = typeof ActivityAction;

export type ActivityActionType = keyof ActivityActions;

export const getUserActivityActions = () => {
  const actions = Object.values(UserActivityAction) as unknown;
  return actions as ActivityActionType[];
};

export const getAdminActivityActions = () => {
  const actions = Object.values(AdminActivityAction) as unknown;
  return actions as ActivityActionType[];
};

export function getActivityTitle(action: ActivityActionsUnion): string {
  switch (action) {
    case ActivityAction.EmailBounced:
      return 'Bounced email';
    case ActivityAction.EmailClicked:
      return 'Clicked link in email';
    case ActivityAction.EmailComplaint:
      return 'Complaint email';
    case ActivityAction.EmailDelivered:
      return 'Delivered email';
    case ActivityAction.EmailOpened:
      return 'Opened email';
    case ActivityAction.EmailUnsubscribed:
      return 'Unsubscribed from email';
    case ActivityAction.SmsClicked:
      return 'Clicked link in SMS';
    case ActivityAction.SmsDelivered:
      return 'Delivered SMS';
    case ActivityAction.SmsFailed:
      return 'Failed SMS';
    case ActivityAction.SmsUndelivered:
      return 'Undelivered SMS';
    case ActivityAction.UserCreated:
      return 'User has been created';
    case ActivityAction.UserUpdated:
      return 'User has been updated';
    case ActivityAction.UserLogin:
      return 'Logged in on';
    case ActivityAction.UserForgotPassword:
      return 'User forgot password request';
    case ActivityAction.AppRegistrationSuccessful:
      return 'Registered to application';
    case ActivityAction.AppSubmitted:
      return 'Submitted application';
    case ActivityAction.AppPaid:
      return 'Paid application';
    case ActivityAction.AppDepositPaid:
      return 'Paid application deposit';
    case ActivityAction.AppCompleted:
      return 'Completed application';
    case ActivityAction.RecommendationRequested:
      return 'Recommendation Requested';
    case ActivityAction.RecommendationReceived:
      return 'Recommendation Received';
    case ActivityAction.SurveyAnswered:
      return 'Answered Survey';
    case ActivityAction.PageView:
      return 'Viewed page';
    case ActivityAction.LinkClick:
      return 'Clicked on link';
    case ActivityAction.FormSubmitted:
      return 'Submitted form';
    case ActivityAction.FormPaid:
      return 'Paid form';
    case ActivityAction.CustomActivityCompleted:
      return 'Custom activity completed';
    case ActivityAction.EventRegistrationSuccessful:
      return 'Registered for event';
    case ActivityAction.EventRegistrationUpdated:
      return 'Updated registration for event';
    case ActivityAction.EventRegistrationCanceled:
      return 'Canceled registration for event';
    case ActivityAction.EventMarkedAttended:
      return 'Marked attended for event';
    case ActivityAction.DecisionAssignmentUpdated:
      return 'Assignment updated';
    case ActivityAction.DecisionScoreUpdated:
      return 'Score changed';
    case ActivityAction.DecisionStatusUpdated:
      return 'Status changed';
    case ActivityAction.DecisionStageUpdated:
      return 'Stage changed';
    case ActivityAction.DecisionReleased:
      return 'Decision released';
    case ActivityAction.DecisionEnteredCohort:
      return 'Entered cohort';
    case ActivityAction.DecisionExitedCohort:
      return 'Exited cohort';
    case ActivityAction.DecisionNoteAdded:
      return 'Note added';
    case ActivityAction.DecisionNoteUpdated:
      return 'Note updated';
    case ActivityAction.DecisionNoteDeleted:
      return 'Note deleted';
    case ActivityAction.DecisionCriteriaUpdated:
      return 'Criteria changed';
    case ActivityAction.DecisionCriteriaDeleted:
      return 'Criteria deleted';
    case ActivityAction.DecisionChecklistItemAdded:
    case ActivityAction.DecisionChecklistItemUpdated:
    case ActivityAction.DecisionChecklistItemRemoved:
      return 'Checklist updated';
    case ActivityAction.DecisionChecklistCompleted:
      return 'Checklist completed';
    case ActivityAction.UserMerged:
      return 'Merged user';
    case ActivityAction.UserProfileUpdated:
      return 'Updated user';
    case ActivityAction.UserProfileAddressUpdated:
      return 'Updated user address';
    case ActivityAction.UserProfileEmailUpdated:
      return 'Updated user email';
    case ActivityAction.UserProfileMilestonesUpdated:
      return 'Updated user milestones';
    case ActivityAction.UserProfileMilestoneUpdated:
      return 'Updated user milestone';
    case ActivityAction.UserProfileNameUpdated:
      return 'Updated user name';
    case ActivityAction.UserTerritoryChanged:
      return 'Updated territory';
    case ActivityAction.TaskAssigned:
      return 'Task assigned';
    case ActivityAction.TaskCreated:
      return 'Task created';
    case ActivityAction.TaskCompleted:
      return 'Task completed';
    case ActivityAction.TaskDeleted:
      return 'Task deleted';
    case ActivityAction.UserDeleted:
      return 'Deleted user';
    case ActivityAction.ConversationAddedParticipant:
      return 'Added conversation participant';
    case ActivityAction.ConversationAssignmentUpdated:
      return 'Conversation assigned';
    case ActivityAction.ConversationClosed:
      return 'Conversation closed';
    case ActivityAction.ConversationDeleted:
      return 'Conversation deleted';
    case ActivityAction.ConversationFavoriteUpdated:
      return 'Conversation added to favorite';
    case ActivityAction.ConversationJoined:
      return 'Conversation joined';
    case ActivityAction.ConversationLeave:
      return 'Conversation leave';
    case ActivityAction.ConversationOpened:
      return 'Conversation opened';
    case ActivityAction.ConversationTagUpdated:
      return 'Conversation tag updated';
    case ActivityAction.CourseCompleted:
      return 'Course completed';
    case ActivityAction.CourseDropped:
      return 'Course dropped';
    case ActivityAction.CourseEnrolled:
      return 'Course enrolled';
    case ActivityAction.CourseUpdated:
      return 'Course updated';
    case ActivityAction.PhoneCallCompleted:
      return 'Phone call completed';
    case ActivityAction.PhoneCallBusy:
      return 'Phone call busy';
    case ActivityAction.PhoneCallCancelled:
      return 'Phone call cancelled';
    case ActivityAction.PhoneCallFailed:
      return 'Phone call failed';
    case ActivityAction.PhoneCallNotAnswered:
      return 'Phone call not answered';
    case ActivityAction.AppointmentAttended:
      return 'Attended appointment';
    case ActivityAction.AppointmentCanceled:
      return 'Canceled appointment';
    case ActivityAction.AppointmentNoShow:
      return "Didn't show for an appointment";
    case ActivityAction.AppointmentScheduled:
      return 'Scheduled an appointment';
    case ActivityAction.AppointmentUpdated:
      return 'Updated appointment';
    default:
      console.error(`"${action}" activity action is not handled`);
      return '';
  }
}
