import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormsApi } from '@element451-libs/models451';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import {
  Api451Done,
  ElmResponse,
  toEncodedItem,
  toItem,
  urlEncodedHeaders
} from '../shared';
import { FormsApiModule } from './forms-api.module';

// shorthand
type R<T> = ElmResponse<T>;

@Injectable({
  providedIn: FormsApiModule
})
export class FormsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  get(guid: string) {
    return this.http.get<R<FormsApi.Form>>(this.url(`forms/private/${guid}`));
  }

  getAll() {
    return this.http.get<R<FormsApi.PrivateForm[]>>(this.url(`forms/private`));
  }

  getList(params?: Record<string, string>) {
    return this.http.get<R<FormsApi.Form[]>>(this.url(`forms/list`), {
      params
    });
  }

  getPublic(guid: string) {
    return this.http.get<R<FormsApi.PublicForm>>(
      this.url(`forms/public/${guid}`)
    );
  }

  getSettings() {
    return this.http.get<R<FormsApi.Settings>>(
      this.url(`forms/public/settings`)
    );
  }

  add(form: Partial<FormsApi.Form>) {
    return this.http.post<R<FormsApi.Form>>(
      this.url(`forms`),
      toEncodedItem(form),
      urlEncodedHeaders
    );
  }

  update(guid: string, form: Partial<FormsApi.Form>) {
    return this.http.put<R<FormsApi.Form>>(
      this.url(`forms/${guid}`),
      toEncodedItem(form),
      urlEncodedHeaders
    );
  }

  getFields(guid: string) {
    return this.http.get<R<{ fields: FormsApi.Field[] }>>(
      this.url(`fields/${guid}`)
    );
  }

  updateFields(guid: string, fields: FormsApi.Field[]) {
    return this.http.put<R<FormsApi.Field[]>>(
      this.url(`fields/${guid}`),
      toEncodedItem({ fields }),
      urlEncodedHeaders
    );
  }

  createFields(fields: FormsApi.Field[]) {
    return this.http.post<R<FormsApi.FormData>>(
      this.url(`fields`),
      toEncodedItem({ fields }),
      urlEncodedHeaders
    );
  }

  createForm(form: Partial<FormsApi.FormData>) {
    return this.http.post<R<FormsApi.FormData>>(
      this.url(`fields`),
      toEncodedItem(form),
      urlEncodedHeaders
    );
  }

  updateForm(guid: string, form: Partial<FormsApi.FormData>) {
    return this.http.put<R<FormsApi.FormData>>(
      this.url(`fields/${guid}`),
      toEncodedItem(form),
      urlEncodedHeaders
    );
  }

  delete(guid: string) {
    return this.http.delete<R<any>>(this.url(`forms/${guid}`));
  }

  duplicate(guid: string) {
    return this.http.post<R<FormsApi.Form>>(
      this.url(`forms/${guid}/clone`),
      null
    );
  }

  saveFormData(guid: string, payload: FormsApi.SaveFormPayload) {
    const { files, ...item } = payload;
    const formData = new FormData();
    if (files && files.length > 0)
      files.forEach(file => {
        formData.append(file.name, file.file);
      });

    formData.append('item', JSON.stringify(item));

    return this.http.post<
      // request_id is used for permissions for file uploads
      R<Api451Done & FormsApi.SaveFormResponse>
    >(this.url(`forms/public/${guid}`), formData);
  }

  saveFormDataSimple(guid: string, item: FormsApi.SaveFormPayload) {
    return this.http.post<R<Api451Done & { user_id: string }>>(
      this.url(`forms/public/${guid}`),
      { item }
    );
  }

  /**
   * This is an old route that uses fields id for referencing forms.
   * It knows which user data to query based on the Authorization header.
   * Also locker id can be used as an authorization token.
   */
  getUserDataByFields(formFieldsGuid: string) {
    return this.http.get<R<FormsApi.FormWithUserData>>(
      this.url(`data/forms/${formFieldsGuid}`)
    );
  }

  verifyRecaptcha(token: string) {
    return this.http.post<R<FormsApi.RecaptchaResponse>>(
      this.url(`forms/recaptcha/siteverify`),
      { token }
    );
  }

  updateVerificationForm(formId: string, data: FormsApi.FormVerification) {
    return this.http.put<R<FormsApi.FormVerification>>(
      this.url(`forms/${formId}`),
      toItem({ auth_form: data })
    );
  }

  verifyUser(formId: string, identity: string) {
    return this.http.post<R<FormsApi.FormVerificationResponse>>(
      this.url(`forms/public/${formId}/auth`),
      { identity }
    );
  }

  confirmUserVerification(
    formId: string,
    code: string,
    authorizationKey: string
  ) {
    const headers = new HttpHeaders({
      Authorization: authorizationKey
    });

    return this.http.post<R<FormsApi.FormConfirmationResponse>>(
      this.url(`forms/public/${formId}/confirm`),
      { code },
      { headers }
    );
  }
}
