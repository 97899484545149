import { Platform } from '@angular/cdk/platform';
import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule
} from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserDocumentRefModule } from '@element451-libs/utils451/document';
import { TraceService } from '@sentry/angular-ivy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SentryErrorHandler } from './core/error.service';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BrowserDocumentRefModule,

    CoreModule.forRoot(),

    AppRoutingModule,
    HammerModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: TraceService,
      useFactory: (platform: Platform, injector: Injector) => {
        if (platform.isBrowser) {
          return injector.get(TraceService);
        }
        return null;
      },
      deps: [Platform, Injector]
    },
    {
      provide: APP_INITIALIZER,
      /**
       * used as a trick to initialize tracking service
       */
      useFactory: (_: TraceService) => () => {},
      deps: [TraceService],
      multi: true
    },
    { provide: APP_ID, useValue: 'app451' }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
