import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { head, isPlainObject } from 'lodash';

import { ApplicationsApi } from '@element451-libs/api451';

import { DashboardService } from '../dashboard';
import { InfoRequestService } from '../info-request';
import { Steps } from '../steps';

import { SupplementalFormsService } from '../supplemental-forms';
import * as actions from './tips-and-info.actions';

@Injectable()
export class TipsAndInfoService {
  sidebarContent$ = this._getSidebarContent().pipe(shareReplay(1));

  hasSidebarContent$ = this.sidebarContent$.pipe(
    map(sidebarContent => isPlainObject(sidebarContent))
  );

  constructor(
    private store: Store<any>,
    private steps: Steps,
    private dashboard: DashboardService,
    private infoRequest: InfoRequestService,
    private supplementalForms: SupplementalFormsService
  ) {}

  openTipsAndInfo() {
    this.store.dispatch(new actions.OpenTipsAndInfoAction());
  }

  private _getSidebarContent() {
    return combineLatest([
      this._getStepOrSectionSidebar(),
      this._getInfoRequestSidebar(),
      this._getWelcomePageSidebar(),
      this._getSupplementalFormSidebar()
    ]).pipe(
      map(sidebars => sidebars.filter(isPlainObject)),
      map(head)
    );
  }

  private _getStepOrSectionSidebar() {
    return combineLatest([
      this.steps.openedStep$,
      this.steps.entities$,
      this.steps.openedSection$
    ]).pipe(
      map(([openedStepId, stepEntities, openedSectionId]) => {
        const step = stepEntities[openedStepId];

        if (step && openedSectionId) {
          const section = step.sections.find(
            _section => _section.id === openedSectionId
          );

          return getSidebarContentIfValid(section, step.sidebar_content);
        }
        return getSidebarContentIfValid(step);
      })
    );
  }

  private _getWelcomePageSidebar() {
    return combineLatest([
      this.dashboard.isWelcomePageOpened$,
      this.dashboard.dashboard$
    ]).pipe(map(mapToSidebarContent));
  }

  private _getInfoRequestSidebar() {
    return combineLatest([
      this.infoRequest.opened$,
      this.infoRequest.infoRequest$
    ]).pipe(map(mapToSidebarContent));
  }

  private _getSupplementalFormSidebar() {
    return combineLatest([
      this.supplementalForms.selectedStep$.pipe(map(Boolean)),
      this.supplementalForms.selectedStep$
    ]).pipe(map(mapToSidebarContent));
  }
}

function mapToSidebarContent([isOpened, hasSidebar]: [
  boolean,
  { sidebar_content: ApplicationsApi.SidebarContent }
]) {
  return isOpened ? getSidebarContentIfValid(hasSidebar) : null;
}

function getSidebarContentIfValid(
  hasSidebar: { sidebar_content: ApplicationsApi.SidebarContent },
  fallback: ApplicationsApi.SidebarContent | null = null
) {
  const hasSidebarContent =
    hasSidebar && isPlainObject(hasSidebar.sidebar_content);
  const hasSidebarContentBlocks =
    hasSidebarContent &&
    hasSidebar.sidebar_content.info_blocks &&
    hasSidebar.sidebar_content.info_blocks.length;

  return hasSidebar && hasSidebarContentBlocks
    ? hasSidebar.sidebar_content
    : fallback;
}
