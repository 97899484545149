/* eslint-disable @typescript-eslint/no-empty-interface */
import { ConditionsApi } from '../conditions';
import { FilesApi } from '../files';
import { MilestonesApi } from '../milestones';
import { PhoneApi } from '../phone';
import { SegmentsApi } from '../segments';
import { StudentAssistantsApi } from '../student-assistants';
import { TagsApi } from '../tags';
import { UsersApi } from '../users';

export interface AttachmentUrl {
  url: string;
}

export enum ConversationType {
  Group = 'group',
  Internal = 'internal'
}

export enum ConversationStatus {
  Open = 'open',
  Closed = 'closed'
}

export enum DeliveryMethod {
  Email = 'email',
  Sms = 'sms',
  Web = 'web',
  PhoneCall = 'phone_call',
  WhatsApp = 'whatsapp'
}

export const WHATSAPP_CHARACTER_LIMIT = 4096;

export const SMS_CHARACTER_LIMIT = 160;

export type ChatbotChannels =
  | DeliveryMethod.Email
  | DeliveryMethod.Sms
  | DeliveryMethod.WhatsApp
  | DeliveryMethod.Web;

export enum DateFilter {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'this_week',
  Last7Days = 'last_7_days',
  ThisMonth = 'this_month',
  Last30Days = 'last_30_days'
}

export enum QueryParams {
  AssignedTo = 'assigned_to',
  AssignedToOthers = 'assigned_to_others',
  IsParticipant = 'is_participant',
  IsOpened = 'is_opened',
  IsFavorite = 'is_favorite',
  HasAttachments = 'has_attachments',
  ExternalParticipantId = 'external_participant_id',
  SearchText = 'search_text',
  DateFilter = 'date_filter',
  TagId = 'tag_id',
  Channels = 'channels',
  SelectedConversation = 'selected_conversation',
  ChatbotAnswered = 'chatbot_answered',
  ChatbotHandoff = 'chatbot_handoff'
}

export interface SavedReplyDto {
  guid: string;
  title: string;
  subject: string;
  message: string;
  subdom: string;
  channels: DeliveryMethod[] | null | undefined;
  updated_by: string;
  updated_at: string;
  created_by: string;
  created_at: string;
}

export type SavedReplyEditableProperties = Pick<
  SavedReplyDto,
  'title' | 'subject' | 'message' | 'channels'
>;

export interface CreateSavedReplyDto extends SavedReplyEditableProperties {}
export interface UpdateSavedReplyDto extends SavedReplyEditableProperties {}

export interface SavedRepliesResponse {
  count: number;
  replies: SavedReplyDto[];
}

/**
 * Rules
 */
export type RuleOperator = 'and' | 'or';

export interface Rule {
  guid: string;
  name: string;
  description: string;
  active: boolean;
  action?: Action;
  actions?: Action[];
  priority: number;
  rule_operator: RuleOperator;
  rules: ConditionsApi.Condition[];
  created_by: string;
  created_at: string;
  updated_at: string;
}

export interface ChatbotHandoffIntent {
  intent: string;
}

export interface ChatbotHandoffRule {
  intent: string;
  guid: string;
}

export enum ChatbotHandoffType {
  Specific = 'specific',
  All = 'all'
}

export enum ChatbotTools {
  StartApplication = 'start_application',
  GetApplicationStatus = 'get_application_status',
  ApplicationStatusChecklist = 'application_status_checklist',
  ScheduleAppointment = 'schedule_appointment',
  RegisterForEvent = 'register_for_event',
  ClientKnowledgeBase = 'client_knowledge_base',
  CommonAppKnowledgeBase = 'common_app_knowledge_base',
  FinancialAidKnowledgeBase = 'financial_aid_knowledge_base',
  InquiryFlow = 'inquiry_flow',
  ProgramExplorer = 'program_explorer',
  CourseCatalog = 'course_catalog',
  TeamMember = 'team_member'
}

export interface ChatbotToolListItem {
  name: string;
  value: ChatbotTools;
}

export const CHATBOT_TOOLS_LIST: ChatbotToolListItem[] = [
  {
    name: 'Human Team Member Handoff',
    value: ChatbotTools.TeamMember
  },
  {
    name: 'Start an Application',
    value: ChatbotTools.StartApplication
  },
  {
    name: 'Get Application Status',
    value: ChatbotTools.GetApplicationStatus
  },
  {
    name: 'Application Status Checklist',
    value: ChatbotTools.ApplicationStatusChecklist
  },
  {
    name: 'Register for Event',
    value: ChatbotTools.RegisterForEvent
  },
  {
    name: 'Schedule Appointments',
    value: ChatbotTools.ScheduleAppointment
  },
  {
    name: 'Common App Knowledge',
    value: ChatbotTools.CommonAppKnowledgeBase
  },
  {
    name: 'Financial Aid Knowledge',
    value: ChatbotTools.FinancialAidKnowledgeBase
  },
  {
    name: 'Inquiry Flow',
    value: ChatbotTools.InquiryFlow
  },
  {
    name: 'Program Explorer',
    value: ChatbotTools.ProgramExplorer
  },
  {
    name: 'Course Catalog',
    value: ChatbotTools.CourseCatalog
  }
];

export const ALL_CHATBOT_TOOLS = Object.values(ChatbotTools);

export enum KnowledgeBaseCategories {
  CommonApp = 'common-app',
  CourseInformation = 'course-information',
  ProgramInformation = 'program-information',
  ProgramCareerInformation = 'program-career-information',
  ProgramTermInformation = 'program-term-information',
  /**
   * Available for customers to pick
   */
  FinancialAid = 'financial-aid',
  Admissions = 'admissions',
  Academics = 'academics',
  StudentLife = 'student-life',
  Athletics = 'athletics',
  Career = 'career',
  StudentServices = 'student-services',
  General = 'general',
  Alumni = 'alumni',
  InternationStudents = 'international-students',
  DomesticStudents = 'domestic-students',
  GraduateStudents = 'graduate-students',
  UndergraduateStudents = 'undergraduate-students',
  ContinuingEducation = 'continuing-education',
  Housing = 'housing'
}

export interface Draft {
  message?: string;
  note?: string;
  conversation_guid: string;
  author_id: string;
}

export interface TypingActivity {
  conversation_guid: string;
  user_ids: string[];
  external_user_typing?: boolean;
  chatbot_typing?: boolean;
  chatbot_status?: string;
}

export interface TypingActivityItem {
  conversationGuid: string;
  isTyping: boolean;
}

export type RuleApiRequiredProps = Pick<
  Rule,
  'name' | 'action' | 'rules' | 'action'
>;
export type RuleEditableProps = Omit<
  Rule,
  'guid' | 'created_by' | 'created_at' | 'updated_at'
>;
export type RuleUpdatePayload = Partial<RuleEditableProps> &
  RuleApiRequiredProps;

export interface Action {
  type: ActionType;
  parameters: ActionParameters;
}

export enum ActionType {
  AssignTo = 'assign_to',
  ReplyBack = 'reply_back',
  TagConversation = 'tag_conversation',
  AddParticipant = 'add_participants',
  CloseConversation = 'close_conversation',
  WorkflowRule = 'execute_workflow'
}

export type ActionParameters =
  | AssignActionParameters
  | ParticipantsActionParameters
  | ReplyActionParameters
  | TagActionParameters
  | WorkflowRuleActionParameters;

export interface AssignActionParameters {
  user_id: string;
}

export interface ConversationLocation {
  host: string;
  path: string;
}

export interface ExternalConversationCreateDto {
  message?: string;
  attachment?: File | null;
  location: ConversationLocation;
  namespace?: string;
  assistant?: string;
  search_thread_guid?: string;
  test?: boolean;
  is_audio?: boolean | null;
}

export interface ConversationCreateBaseDto {
  delivery_method: DeliveryMethod;
  recipient_id: string;
  message?: string;
  inbox_setting_id?: string;
  attachment?: File;
  title?: string;
  participants?: string[];
  assignee?: string | null;
}

export interface EmailConversationCreateDto extends ConversationCreateBaseDto {
  delivery_method: DeliveryMethod.Email;
}

export interface SmsConversationCreateDto extends ConversationCreateBaseDto {
  delivery_method: DeliveryMethod.Sms;
  client_phone_number?: string;
  user_phone_number?: string;
}

export interface WhatsAppConversationCreateDto
  extends ConversationCreateBaseDto {
  delivery_method: DeliveryMethod.WhatsApp;
  client_phone_number?: string;
  user_phone_number?: string;
}

export interface PhoneCallConversationCreateDto
  extends ConversationCreateBaseDto {
  delivery_method: DeliveryMethod.PhoneCall;
  to_phone_number?: string;
  from_phone_number?: string;
  is_recording_on?: boolean;
  phone_call_id?: string;
}

export type ConversationCreateDTO =
  | EmailConversationCreateDto
  | SmsConversationCreateDto
  | WhatsAppConversationCreateDto
  | PhoneCallConversationCreateDto;

export interface ParticipantsActionParameters {
  user_ids: string[];
}

export interface TagActionParameters {
  taxonomy_guids: string[];
}

export interface WorkflowRuleActionParameters {
  workflow_guid: string;
}

export interface ReplyActionParameters {
  message: string | File;
}

export class ConversationError extends Error {
  constructor(
    readonly statusCode: number,
    readonly errorMessage: string,
    readonly explanation?: string
  ) {
    super(errorMessage);
  }

  withExplanation(explanation: string): ConversationError {
    return new ConversationError(
      this.statusCode,
      this.errorMessage,
      explanation
    );
  }
}

export const ConversationErrors = {
  badRequest: new ConversationError(400, 'Bad request'),
  unauthorized: new ConversationError(401, 'Unauthorized'),
  tokenExpired: new ConversationError(401, 'Token expired'),
  forbidden: new ConversationError(403, 'Forbidden'),
  notFound: new ConversationError(404, 'Not Found'),
  conflict: new ConversationError(409, 'Conflict on update'),
  internal: new ConversationError(500, 'Internal server error'),
  userNotFound: new ConversationError(404, 'User not found'),
  conversationNotFound: new ConversationError(404, 'Conversation not found'),
  taxonomyNotFound: new ConversationError(404, 'Taxonomy not found'),
  partNotFound: new ConversationError(404, 'Conversation part not found'),
  fileNotFound: new ConversationError(404, 'File not found'),
  savedReplyNotFound: new ConversationError(404, 'Saved reply not found'),
  userAlreadyParticipant: new ConversationError(400, 'User already participant')
};

export enum SocketEvents {
  /**
   * Socket Response Events
   */
  ConversationCreated = 'conversation_created',
  ConversationClosed = 'conversation_closed',
  NoteAdded = 'note_added',
  NoteEdited = 'note_edited',
  CommentAdded = 'comment_added',
  AssigneeAdded = 'assignee_added',
  AssigneeRemoved = 'assignee_removed',
  ParticipantAdded = 'participant_added',
  ParticipantLeft = 'participant_left',
  ConversationTagged = 'conversation_tagged',
  ConversationUnTagged = 'conversation_untagged',
  ConversationRenamed = 'conversation_renamed',
  InfoCapturePrompted = 'info_capture_prompted',
  EmailCaptureResponded = 'email_capture_responded',
  NameCaptureResponded = 'name_capture_responded',
  ExternalLastVisitedUpdated = 'external_last_visited_updated',
  ConversationStudentAssistantChanged = 'conversation_student_assistant_changed',

  ConversationInboxChanged = 'conversation_inbox_changed',
  PhoneCallPartEdited = 'phone_call_part_edited',
  PhoneCallStarted = 'phone_call_started',
  ChatBotToggled = 'chatbot_toggled',
  PartSentimentAnalysed = 'part_sentiment_analysed',
  WebsiteArticleSitemapLoaded = 'website_article_sitemap_loaded',
  /** This Event will be only sent to currently logged in user */
  LastVisitedUpdated = 'last_visited_updated',
  UserAssociated = 'user_associated',
  ConversationOpened = 'conversation_opened',
  AttachmentAdded = 'attachment_added',
  ConversationDeleted = 'conversation_deleted',
  UserOnline = 'user_online',
  UserOffline = 'user_offline',
  AnonymousUserUpdated = 'anonymous_user_updated',
  UsersMerged = 'users_merged',
  ExternalTypingActivityUpdated = 'external_typing_activity_updated',
  InternalTypingActivityUpdated = 'internal_typing_activity_updated',
  ChatbotTypingActivityUpdated = 'chatbot_typing_activity_updated',
  ChatbotNewMessageChunk = 'chatbot_new_message_chunk',
  UrlArticleIndexed = 'url_article_indexed',
  ArticleFromFileIndexed = 'file_article_indexed',
  YoutubeVideoArticleIndexed = 'youtube_video_article_indexed',
  /**
   * Socket Request Events
   */
  AddComment = 'add_comment',
  AddComments = 'add_comments',
  MarkAsSeen = 'mark_as_seen',
  ErrorHappened = 'error_happened',
  UpdateExternalLastVisited = 'update_external_last_visited',
  EmailCapture = 'email_capture',
  NameCapture = 'name_capture',
  ChangeConversationStudentAssistant = 'change_conversation_student_assistant',
  UpdateExternalTypingActivity = 'update_external_typing_activity',
  UpdateInternalTypingActivity = 'update_internal_typing_activity',
  UpdateChatbotTypingActivity = 'update_chatbot_typing_activity'
}

export enum ConversationPartType {
  Comment = 'comment',
  Open = 'open',
  Close = 'close',
  Assign = 'assign',
  Unassign = 'unassign',
  AddParticipant = 'add_participant',
  RemoveParticipant = 'remove_participant',
  Rename = 'rename',
  Leave = 'leave',
  Join = 'join',
  Note = 'note',
  Attachment = 'attachment',
  EmailCapturePrompt = 'email_capture_prompt', // to keep the old parts working
  InfoCapturePrompt = 'info_capture_prompt',
  EmailCaptureResponse = 'email_capture_response',
  NameCaptureResponse = 'name_capture_response',
  PhoneCall = 'phone_call',
  ChatBotToggle = 'chatbot_toggle',
  StudentAssistantChanged = 'student_assistant_changed'
}

// this is just copied from luminous-realtime-api
export interface UserDto {
  id: string; // ObjectID hex
  first_name: string;
  last_name: string;
  email?: string;
  type: UsersApi.UserType; // external by default
  avatar?: UsersApi.UserAvatar;
  phones?: PhoneApi.Phone[];
  milestones?: MilestonesApi.Milestone[];
}

export enum ConversationPartVisibility {
  External = 'external',
  Internal = 'internal'
}

export enum ParticipantStatus {
  Seen = 'seen',
  Sent = 'sent',
  Notified = 'notified'
}

export enum ConversationPhoneCallPartState {
  PhoneCallStarted = 'phone_call_started',
  PhoneCallCompleted = 'phone_call_completed'
}

export enum ConversationPromptPartState {
  PromptMain = 'prompt_main',
  PromptFollowUp = 'prompt_follow_up',
  PromptThankYou = 'prompt_thank_you'
}

export type ConversationPartState =
  | ConversationPhoneCallPartState
  | ConversationPromptPartState;

export interface ParticipantStatusesOfPart {
  type: UsersApi.UserType;
  status: ParticipantStatus;
  user_id?: string;
  anonymous_guid?: string;
  is_anonymous: boolean;
}

export enum PartAuthorType {
  Internal = 'internal',
  External = 'external',
  System = 'system',
  Bot = 'bot'
}

export interface PartAuthor {
  user_id?: string;
  // maybe this property is not even needed, because if it is external participant,
  // we can just check `conversation.external_participant` for author data
  anonymous_guid?: string;
  type: PartAuthorType;
  user?: UserDto;
  rule_name?: string;
}

export interface ConversationPartsResponse {
  parts: ConversationPartBaseDto[];
  hasPrevious: boolean;
}

export enum ConversationPartRating {
  Like = 'like',
  Dislike = 'dislike'
}

export interface RatePartDto {
  rating: ConversationPartRating;
}

export interface ConversationPartBaseDto {
  _id: string;
  guid: string;
  subdom: string;
  conversation_guid: string;
  author: PartAuthor;
  type: ConversationPartType;
  visibility: ConversationPartVisibility;
  updated_at: string;
  created_at: string;
  // this is used for faster showing of messages on FE, even if they are still not received through socket
  temp_guid?: string;
  // conversation context
  conversation?: ConversationDto;
  content: any;
  source?: string;
  phone_call?: PhoneCallData;
  sentiment_evaluation?: number;
  rating?: ConversationPartRating;
  chatbot_handoff?: boolean;
  chatbot_sources?: string[];
  assistant?: ChatbotPartAssistant;
  /**
   * @deprecated
   */
  metadata?: ChatbotResponseMetadata;
  metadata_stack?: ChatbotMetadataStack;
  part_status?: {
    status?: string;
  };
}

export interface ChatbotPartAssistant {
  name: string;
  title?: string;
  guid: string;
  logo: string;
  assistant_type: StudentAssistantsApi.StudentAssistantType;
}

export interface PhoneCallData {
  to_phone_number?: string;
  from_phone_number?: string;
  is_recording_on?: boolean;
  phone_call_id?: string;
}

export interface AddParticipantPartDto extends ConversationPartBaseDto {
  content: {
    target: string; // what is happening if we add multiple users at once? is it possible?
  };
}

export interface AssignPartDto extends ConversationPartBaseDto {
  content: {
    target: string;
  };
}

export interface AttachmentPartFile {
  file_id: string;
  description: string;
  size: number;
  name: string;
  mime: string;
  privacy: string;
  url: string;
  is_audio?: boolean;
  transcription?: string;
}

export interface AttachmentPartDto extends ConversationPartBaseDto {
  content: AttachmentPartFile;
}

export interface ClosePartDto extends ConversationPartBaseDto {
  content: Record<string, never>;
}

export interface CommentPartDto extends ConversationPartBaseDto {
  content: {
    message: string;
    translation?: string;
  };
}

export interface JoinPartDto extends ConversationPartBaseDto {
  content: {
    target: string;
  };
}

export interface LeavePartDto extends ConversationPartBaseDto {
  content: {
    target: string;
  };
}

export interface NotePartDto extends ConversationPartBaseDto {
  content: {
    message: string;
  };
}

export interface OpenPartDto extends ConversationPartBaseDto {
  content: Record<string, never>;
}

export interface RemoveParticipantPartDto extends ConversationPartBaseDto {
  content: {
    target: string; // here is the same question, do we allow multiple users to be removed in one API call
  };
}

export interface RenamePartDto extends ConversationPartBaseDto {
  content: {
    old: string | null;
    new: string;
  };
}

export interface UnassignPartDto extends ConversationPartBaseDto {
  content: {
    target: string;
  };
}

export interface ChatBotTogglePartDto extends ConversationPartBaseDto {
  content: {
    chatbot_state: ChatbotState;
  };
}

export interface StudentAssistantChangedPartDto
  extends ConversationPartBaseDto {
  content: {
    student_assistant: string;
  };
}

export interface InfoCapturePromptPartDto extends ConversationPartBaseDto {
  content: {
    message: string;
    state: string;
  };
}

export interface EmailCaptureResponsePartDto extends ConversationPartBaseDto {
  content: {
    email: string;
  };
}

export interface NameCaptureResponsePartDto extends ConversationPartBaseDto {
  content: {
    first_name: string;
    last_name: string;
  };
}

export interface ChatbotNewMessageChunkDto {
  conversation_guid: string;
  sequence: number;
  token: string;
  temp_guid: string;
  assistant: ChatbotPartAssistant;
}

export interface PhoneCallPartDto extends ConversationPartBaseDto {
  content: {
    state: string;
    call_duration?: number;
    transcription_file_guid?: string;
    recording_file_guid?: string;
    call_ended_at?: string;
  };
}

export interface AnonymousParticipantData {
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  is_captured?: boolean;
}

export interface AnonymousParticipantUpdatedDto {
  anonymous_guid: string;
  data: AnonymousParticipantData;
  conversation_guids: string[];
}

export interface CaptureEmailDto {
  email: string;
}

export interface CaptureNameDto {
  first_name?: string;
  last_name?: string;
}

export interface UpdateTypingActivityDto {
  is_typing: boolean;
  conversation_guid: string;
}

export interface UpdateInternalTypingActivityDto {
  is_typing: boolean;
  conversation_guid: string;
  user_id: string;
}

export interface ConversationChangeStudentAssistantDto {
  conversation_guid: string;
  student_assistant_guid: string | null;
}

export enum ChatbotTypingActivityStatus {
  Thinking = 'Thinking...',
  OneMoment = 'One Moment...',
  WorkingOnIt = 'Working on it...',
  StillThinking = 'Still Thinking...',
  AlmostThere = 'Almost There...',
  ThankYou = 'Thank you for your patience...'
}

export interface UpdateChatbotTypingActivityDto {
  conversation_guid: string;
  status: string | null;
  is_typing: boolean;
}

export interface ExternalParticipantDto {
  is_anonymous: boolean;
  user_id?: string;
  anonymous_guid?: string;
  user?: UserDto; // populated data about user
  // this is the data that we collect until we recognize user
  anonymous_data?: AnonymousParticipantData;
  is_online: boolean;
  /**
   * ISO date
   */
  last_visited: string;
  /**
   * ISO date
   */
  last_message_at: string;
}

export interface ConversationDto {
  _id: string;
  guid: string;
  subdom: string;
  author: {
    user_id?: string;
    type: UsersApi.UserType;
  };
  delivery_method: DeliveryMethod;
  inbox_setting_id?: string;
  inbound_channel_id?: string;
  source: DeliveryMethod;
  status: ConversationStatus;
  assignee?: string | null; // I guess it is ok to be a string, because it should be an admin, that will be pre-fetched
  title?: string; // should this be only visible to internal users?
  participants: ConversationParticipantDto[];
  from?: string; // #TODO: Add support for this on API. It could be an email or phone number depending on selected deliveryMethod
  tags: string[];
  favs: string[];
  last_activity: string;
  updated_at: string;
  created_at: string;
  chatbot_answered_at?: string;
  external_participant?: ExternalParticipantDto;
  latest_part?: CommentPartDto | AttachmentPartDto | PhoneCallPartDto;
  origin_event?: any;
  location?: ConversationLocation;
  chatbot_state?: ChatbotState;
  namespace?: string;
  sms_inbox?: {
    client_phone_number: string;
    user_phone_number: string;
  };
  assistants?: {
    active_assistant?: string;
  };
}

export interface ConversationFilter {
  subdom?: string;
  is_favorite?: boolean;
  assignee?: string;
  status?: string;
  limit?: number;
}

export interface ConversationParticipantDto {
  user_id: string;
  last_visited: string; // iso date
}

export interface ConversationList {
  conversations: ConversationDto[];
  hasPrevious: boolean;
}

export interface CreateConversationResponse {
  conversation: ConversationDto;
  part: CommentPartDto;
}

export interface AddSocketComment {
  message: string;
  temp_guid: string;
  conversation_guid: string;
  source?: string;
}

export interface AddSocketComments {
  conversation_guid: string;
  comments: AddSocketComment[];
}

export interface MarkAsSeenDto {
  conversation_guid: string;
  /**
   * ISO string
   */
  date: string;
}
export interface LastVisitedUpdatedDto {
  conversation_guid: string;
  /**
   * ISO date
   */
  last_visited: string;
}

export interface TokenUser {
  isAnonymous: boolean;
  anonymousGuid?: string;
  userId?: string;
  isInternal: boolean;
  type: UsersApi.UserType;
  subdom: string;
}

export interface UserOnlineEvent {
  anonymousGuid: string;
  exp: number;
  iat: number;
  isAnonymous: boolean;
  isInternal: boolean;
  subdom: string;
  type: UsersApi.UserType;
  userId: string;
}

export interface ListConversationsQuery {
  limit?: number;
  before_date?: string; // iso date
  selected_conversation?: string;
}

export interface ConversationFilterQuery extends ListConversationsQuery {
  assigned_to?: string; // userId
  assigned_to_others?: boolean;
  is_participant?: boolean;
  is_anonymous?: boolean;
  external_participant_id?: string;
  channels?: DeliveryMethod[];
  is_opened?: boolean;
  is_favorite?: boolean;
  has_attachments?: boolean;
  date_from?: string;
  date_to?: string;
  search_text?: string; // TODO: search parts content
  date_filter?: DateFilter;
  tag_id?: string[];
  chatbot_answered?: boolean;
  chatbot_handoff?: boolean;
  preview_uuid?: string;
  no_reply?: boolean;
}

export interface MessengerClient {
  pageContext?: {
    width: number;
    height: number;
    url: string;
  };
  apiUrlRT: string;
  featureToken: string;
  analyticsToken: string;
}

export interface MessengerConfigChatbotDto {
  enabled: boolean;
  name: string;
  avatar: string;
  greeting: string;
  limit_available_time: boolean;
  display_chatbot_sources: boolean;
  voice_response?: boolean;
  active_days: number[];
  time_from?: string;
  time_to?: string;
  conditions: SegmentsApi.SegmentValue3 | null;
}

export interface MessengerConfigDto {
  primary_color: string;
  secondary_color: string;
  logo: string;
  name: string;
  icebreakers: IcebreakerDto[];
  conversationStarters: ConversationStarterDto[];
  conditions: SegmentsApi.BrowserSegmentValueFilter | null;
  screen_position: MessengerScreenPosition;
  student_assistants: StudentAssistantsApi.StudentAssistant[];
  student_assistants_groups: StudentAssistantsApi.StudentAssistantsGroup[];
  feature_flags: Record<string, boolean>;
  background_image: string | null;
  enabled: boolean;
  greeting: string;
  intro: string;
  reply_time: MessengerReplyTime;
  limit_available_time: boolean;
  active_days: number[];
  time_from: string;
  time_to: string;
  timezone: string;
  chatbot?: MessengerConfigChatbotDto;
  can_expand_widget?: boolean;
  audio_enabled?: boolean;
}

export interface MessengerConfigCustomization {
  enabled?: boolean;
  logo?: string;
  background_image?: string | null;
  name?: string;
  primary_color?: string;
  secondary_color?: string;
  greeting?: string;
  namespace?: string;
  chatbot_name?: string;
  chatbot_logo?: string;
  starter_messages?: string[];
  forceOpened?: boolean;
  forceExpanded?: boolean;
  preview?: MessengerConfigPreview;
  hideHeaderButtons?: boolean;
  contentFit?: 'contain' | 'fill';
}

export interface MessengerConfigPreview {
  assistant_guid?: string;
}

export interface AuthResponse {
  user: UserDto | TokenUser;
  token: string;
}

export interface SocketQuery {
  token: string;
}

export interface AssociateUserDto {
  anonymous_guid: string;
  user_id: string;
}

export interface CreateAndAssociateUserDto {
  conversationGuid: string;
  user: {
    email: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  };
}

export interface UserAssociatedInternalDto {
  conversation_guids: string[];
  association_data: AssociateUserDto;
  user: UserDto;
  is_online: boolean;
}

export interface UserAssociatedExternalDto {
  token: string;
  user: UserDto;
}

export enum ConversationsSettingsType {
  Rule = 'conversation_rule',
  Icebreaker = 'conversation_icebreaker',
  Settings = 'conversation_settings',
  Article = 'conversation_article',
  ConversationStarter = 'conversation_starter',
  StudentAssistant = 'conversation_student_assistant',
  StudentAssistantsGroup = 'conversation_student_assistants_group'
}

// ICEBREAKERS
export enum IcebreakerRepeatRate {
  EveryDay = '1d',
  EverySecondDay = '2d',
  EveryWeek = '1w',
  EveryMonth = '1m'
}

export interface IcebreakerDto {
  guid: string;
  variant: string;
  title: string;
  body_text: string;
  description: string;
  repeat_rate: IcebreakerRepeatRate | null;
  conditions: SegmentsApi.BrowserSegmentValueFilter | null;
  enabled: boolean;
  subdom: string;
  index_weight: number;
  updated_at: string;
  created_at: string;
  starter_message?: string;
}

export type CreateIcebreakerDto = Pick<
  IcebreakerDto,
  | 'variant'
  | 'title'
  | 'body_text'
  | 'description'
  | 'conditions'
  | 'repeat_rate'
  | 'starter_message'
>;

export type UpdateIcebreakerDto = Partial<
  Pick<
    IcebreakerDto,
    | 'variant'
    | 'title'
    | 'body_text'
    | 'description'
    | 'conditions'
    | 'enabled'
    | 'index_weight'
    | 'repeat_rate'
    | 'starter_message'
  >
>;

export interface BoltBotPreviewDto {
  uuid: string;
  name: string;
  primary_color?: string;
  secondary_color?: string;
  logo?: string;
  greeting?: string;
  urls?: string[];
  starter_messages?: string[];
  chatbot_name?: string;
  chatbot_logo?: string;
  updated_at: string;
  created_at: string;
}

export type CreateBoltBotPreviewDto = Pick<
  BoltBotPreviewDto,
  | 'name'
  | 'primary_color'
  | 'secondary_color'
  | 'logo'
  | 'greeting'
  | 'urls'
  | 'chatbot_logo'
  | 'chatbot_name'
  | 'starter_messages'
>;

export interface ConversationStarterDto {
  guid: string;
  title: string;
  message: string;
  conditions: SegmentsApi.BrowserSegmentValueFilter | null;
  enabled: boolean;
  subdom: string;
  assistants: string[] | null;
  index_weight: number;
  updated_at: string;
  created_at: string;
}

export type CreateConversationStarterDto = Pick<
  ConversationStarterDto,
  'title' | 'message' | 'conditions' | 'assistants'
>;

export type UpdateConversationStarterDto = Partial<
  Pick<
    ConversationStarterDto,
    | 'title'
    | 'message'
    | 'conditions'
    | 'enabled'
    | 'index_weight'
    | 'assistants'
  >
>;

export interface ListArticlesInputDto {
  limit?: number;
  offset?: number;
  search?: string;
  isPrivate?: boolean;
  types?: ArticleType[];
  include?: string[];
  exclude?: string[];
  embedSitemap?: boolean;
}

export enum ArticleType {
  Text = 'text',
  URL = 'url',
  Website = 'website',
  File = 'file',
  CustomAnswers = 'custom_answers',
  YoutubeChannel = 'youtube_channel',
  YoutubeVideo = 'youtube_video'
}

export const PREVIEW_ALLOWED_ARTICLE_TYPES = new Set([
  ArticleType.URL,
  ArticleType.File,
  ArticleType.YoutubeVideo
]);

export interface ArticleCategory {
  label: string;
  slug: string;
}

export const ARTICLE_CATEGORIES: ArticleCategory[] = [
  { label: 'Financial Aid', slug: KnowledgeBaseCategories.FinancialAid },
  { label: 'Admissions', slug: KnowledgeBaseCategories.Admissions },
  { label: 'Academics', slug: KnowledgeBaseCategories.Academics },
  { label: 'Student Life', slug: KnowledgeBaseCategories.StudentLife },
  { label: 'Athletics', slug: KnowledgeBaseCategories.Athletics },
  { label: 'Career', slug: KnowledgeBaseCategories.Career },
  { label: 'Student Services', slug: KnowledgeBaseCategories.StudentServices },
  { label: 'General', slug: KnowledgeBaseCategories.General },
  { label: 'Alumni', slug: KnowledgeBaseCategories.Alumni },
  {
    label: 'International Students',
    slug: KnowledgeBaseCategories.InternationStudents
  },
  {
    label: 'Domestic Students',
    slug: KnowledgeBaseCategories.DomesticStudents
  },
  {
    label: 'Graduate Students',
    slug: KnowledgeBaseCategories.GraduateStudents
  },
  {
    label: 'Undergraduate Students',
    slug: KnowledgeBaseCategories.UndergraduateStudents
  },
  {
    label: 'Continuing Education',
    slug: KnowledgeBaseCategories.ContinuingEducation
  },
  { label: 'Housing', slug: KnowledgeBaseCategories.Housing }
];

export const ARTICLE_CATEGORIES_TAGS: TagsApi.Tag[] = ARTICLE_CATEGORIES.map(
  category => ({
    guid: category.slug,
    name: category.label,
    label: category.label
  })
);

export interface CustomAnswer {
  question: string;
  answer: string;
}

export interface SearchDocumentBlocksInputDto {
  search: string;
  limit: number;
}

export enum ArticleState {
  Indexed = 'indexed',
  Processing = 'processing',
  Failed = 'failed'
}

export interface BaseArticleDto {
  guid: string;
  title: string;
  article_type: ArticleType;
  last_indexed_at: string | null;
  categories: string[];
  created_at: string | null;
  updated_at: string | null;
  state: ArticleState;
  private?: boolean;
  namespace?: string;
}

export interface TextArticleDto extends BaseArticleDto {
  content: string;
  article_type: ArticleType.Text;
}

export interface ArticleFromFileDto extends BaseArticleDto {
  file_guid: string;
  file?: FilesApi.ApiFile | null;
  article_type: ArticleType.File;
}

export interface UrlArticleDto extends BaseArticleDto {
  url: string;
  article_type: ArticleType.URL;
  sync_daily: boolean;
  sitemap_guid?: string;
  sitemap?: WebsiteArticleDto;
}

export interface WebsiteArticleDto extends BaseArticleDto {
  url: string;
  article_type: ArticleType.Website;
  sync_daily: boolean;
  urls: string[];
}

export interface CustomAnswersArticleDto extends BaseArticleDto {
  article_type: ArticleType.CustomAnswers;
  content: string;
}

export interface YoutubeChannelArticleDto extends BaseArticleDto {
  article_type: ArticleType.YoutubeChannel;
  handle: string;
  channel_id: string;
  videos: YoutubeVideo[];
}

export interface YoutubeVideoArticleDto extends BaseArticleDto {
  url: string;
  article_type: ArticleType.YoutubeVideo;
  channel?: YoutubeChannelArticleDto;
  parent_guid?: string;
}

export type ArticleDto =
  | TextArticleDto
  | UrlArticleDto
  | ArticleFromFileDto
  | WebsiteArticleDto
  | CustomAnswersArticleDto
  | YoutubeChannelArticleDto
  | YoutubeVideoArticleDto;

export type CreateTextArticleDto = Pick<
  TextArticleDto,
  'title' | 'categories' | 'content' | 'article_type' | 'private' | 'namespace'
>;

export interface CreateArticleFromFileDto
  extends Pick<
    ArticleFromFileDto,
    'title' | 'categories' | 'article_type' | 'private' | 'namespace'
  > {
  file_guid: string;
}

export type CreateCustomAnswersArticleDto = Pick<
  CustomAnswersArticleDto,
  'title' | 'categories' | 'article_type' | 'content' | 'private' | 'namespace'
>;

export type CreateUrlArticleDto = Pick<
  UrlArticleDto,
  | 'title'
  | 'categories'
  | 'url'
  | 'article_type'
  | 'sync_daily'
  | 'sitemap_guid'
  | 'private'
  | 'namespace'
>;

export type CreateWebsiteArticleDto = Pick<
  WebsiteArticleDto,
  | 'title'
  | 'categories'
  | 'url'
  | 'article_type'
  | 'sync_daily'
  | 'urls'
  | 'private'
  | 'namespace'
>;

export type CreateYoutubeChannelArticleDto = Pick<
  YoutubeChannelArticleDto,
  | 'title'
  | 'categories'
  | 'article_type'
  | 'handle'
  | 'private'
  | 'namespace'
  | 'videos'
>;

export type CreateYoutubeVideoArticleDto = Pick<
  YoutubeVideoArticleDto,
  | 'title'
  | 'categories'
  | 'url'
  | 'article_type'
  | 'private'
  | 'namespace'
  | 'parent_guid'
>;

export type CreateArticleDto =
  | CreateTextArticleDto
  | CreateUrlArticleDto
  | CreateWebsiteArticleDto
  | CreateArticleFromFileDto
  | CreateCustomAnswersArticleDto
  | CreateYoutubeChannelArticleDto
  | CreateYoutubeVideoArticleDto;

export type UpdateArticleDto =
  | Partial<Pick<TextArticleDto, 'title' | 'categories' | 'content'>>
  | Partial<Pick<UrlArticleDto, 'title' | 'categories' | 'sync_daily'>>
  | Partial<Pick<CreateArticleFromFileDto, 'title' | 'categories'>>
  | Partial<Pick<WebsiteArticleDto, 'title' | 'sync_daily'>>
  | Partial<Pick<CustomAnswersArticleDto, 'title' | 'categories' | 'content'>>
  | Partial<Pick<YoutubeChannelArticleDto, 'title' | 'categories'>>
  | Partial<Pick<YoutubeVideoArticleDto, 'title' | 'categories'>>;

// SETTINGS
export enum MessengerScreenPosition {
  Left = 'left',
  Right = 'right'
}

export enum MessengerReplyTime {
  OneMinute = '1m',
  OneHour = '1h',
  OneDay = '1d'
}

export interface ConversationsWebSettingsDto {
  enabled: boolean;
  conditions: SegmentsApi.BrowserSegmentValueFilter | null;
  avatar: string | null;
  background_image: string | null;
  screen_position: MessengerScreenPosition;
  greeting: string;
  intro: string;
  reply_time: MessengerReplyTime;
  limit_available_time: boolean;
  active_days: number[];
  time_from: string;
  time_to: string;
}

export interface ConversationsChatbotSettingsDto {
  chatbot_name: string | null;
  chatbot_type?: ChatbotType;
  chatbot_avatar: string | null;
  chatbot_greeting: string | null;
  conditions: SegmentsApi.SegmentValue3 | null;
  limit_available_time: boolean;
  active_days: number[];
  time_from: string;
  time_to: string;
  channels: ChatbotChannels[];
  tools: ChatbotTools[];
  display_chatbot_sources: boolean;
  voice_response?: boolean;
  limit_to_segments_email?: string[];
  limit_to_segments_sms?: string[];
  limit_to_segments_whatsapp?: string[];
  functions_chatbot_enabled?: boolean;
}

export interface ConversationsPhoneCallSettingsDto {
  recording_enabled: boolean;
  recording_default: boolean;
  default_phone: string | null;
}

export interface ConversationsGeneralSettingsDto {
  sentiment_analysis_enabled?: boolean;
  transcribe_audio_messages?: boolean;
}

export interface ConversationsSettingsDto {
  web: ConversationsWebSettingsDto;
  phone_call: ConversationsPhoneCallSettingsDto;
  general: ConversationsGeneralSettingsDto;
  chatbot: ConversationsChatbotSettingsDto;
}

export interface UpdateConversationsSettingsDto {
  web?: Partial<ConversationsWebSettingsDto>;
  phone_call?: Partial<ConversationsPhoneCallSettingsDto>;
  general?: Partial<ConversationsGeneralSettingsDto>;
  chatbot?: Partial<ConversationsChatbotSettingsDto>;
}

export interface UpdateExternalLastVisitedDto {
  conversation_guid: string;
}

export interface TranscriptionData {
  srt: string;
}

export interface TranscriptionMedia {
  mediaId: string;
  transcripts: TranscriptionData;
}

export interface Transcription {
  media: TranscriptionMedia;
}

export interface TranscriptionSrtItem {
  id: number;
  startSeconds: number;
  endSeconds: number;
  text: string;
  speaker: string;
}

export const phoneCallLocalSettingsStorageKey = 'element451_phonecall_settings';

export interface PhoneCallLocalSettings {
  recording_default: boolean;
  default_phone: string;
}

export enum ConversationPartSentiment {
  Negative = 1,
  Neutral = 2,
  Positive = 3
}

export interface SummarizeConversationResponse {
  summary: string;
}

export interface DraftReplyResponse {
  answer: string;
}

export interface UrlArticleIndexedDto {
  article: UrlArticleDto;
  success: boolean;
}

export interface ArticleFromFileIndexedDto {
  article: ArticleFromFileDto;
  success: boolean;
}

export interface YoutubeVideoArticleIndexedDto {
  article: YoutubeVideoArticleDto;
  success: boolean;
}

export interface WebsiteArticleSitemapLoadedDto {
  articles: UrlArticleDto[];
}

export enum ChatbotState {
  Running = 'running',
  StoppedByUser = 'stopped_by_user',
  StoppedByComment = 'stopped_by_comment',
  HandoffByChatbot = 'handoff_by_chatbot'
}

export interface ChatbotResponseSource {
  url?: string;
  type?: string;
  subdom: string;
}

export enum ChatbotResponseMetadataType {
  EventSignupTool = 'event_signup_tool',
  EventAvailabilityTool = 'event_availability_tool',
  ApplicationStartTool = 'application_start_tool',
  ScheduleAppointmentTool = 'schedule_appointment_tool',
  ScheduleAppointmentAvailabilityTool = 'schedule_appointment_availability_tool',
  ScheduleAppointmentSpecificSlotTool = 'schedule_appointment_specific_slot_tool',
  ApplicationStatusTool = 'application_status_tool',
  Link = 'link'
}

export type ChatbotResponseMetadataData =
  | string
  | EventSignupMetadata[]
  | EventAvailabilityMetadata[]
  | ApplicationsMetadata[]
  | ScheduleAppointmentMetadata[]
  | ScheduleAppointmentAvailabilityMetadata[]
  | ApplicationStatusMetadata[]
  | any;

export interface ChatbotResponseMetadata {
  type: ChatbotResponseMetadataType;
  data: ChatbotResponseMetadataData;
}

export interface ChatbotMetadataStack {
  stack: ChatbotResponseMetadata[];
}

export interface EventSignupMetadata {
  name: string;
  time: string;
  guid: string;
  tstamp: number;
}

export interface ScheduleAppointmentAvailabilityMetadata {
  display: string;
  availability: string;
  id: string;
}

export interface ScheduleAppointmentMetadata {
  name: string;
  category: string;
  guid: string;
  description: string;
}

export interface ApplicationStatusMetadata {
  submitted_at: Date;
  major: string;
  term: string;
  status: string;
  registration_id: string;
  checklist_items: string[];
  stage: string;
  application_name: string;
}

export interface EventAvailabilityMetadata {
  tstamp: number;
  time: string;
}

export interface ApplicationsMetadata {
  name: string;
  guid: string;
}

export enum EventSignupActionType {
  Signup = 'signup',
  Availability = 'availability'
}

export const CHATBOT_GLOBAL_KNOWLEDGE_BASE = 'global';

export enum ChatbotType {
  Advanced = 'advanced',
  Standard = 'standard'
}

export enum ConversationsQuickAction {
  Delete = 'delete',
  Close = 'close',
  AddToFavorites = 'add_to_favorites',
  RemoveFromFavorites = 'remove_from_favorites'
}

export enum FunctionsChatbotType {
  Chatbot = 'chatbot',
  DraftReplyWithoutPrompt = 'draft_reply_without_prompt',
  DraftReplyWithPrompt = 'draft_reply_with_prompt',
  ComposeMessage = 'compose_message'
}

export interface MessengerSendConfirmationEmailDto {
  email: string;
  client_name: string;
}

export interface MessengerConfirmVerificationCodeDto {
  email: string;
  code: string;
}

export enum MessengerConfirmVerificationCodeState {
  EmailVerifiedAndUserFound = 'email_verified_and_user_exists',
  EmailVerifiedAndUserNotFound = 'email_verified_and_user_not_found',
  EmailNotVerified = 'email_not_verified'
}

export interface MessengerConfirmVerificationCodeResponseDto {
  user?: UserDto;
  state: MessengerConfirmVerificationCodeState;
  email: string;
}

export interface BaseInboundChannel {
  type: DeliveryMethod;
  /**
   * subdom
   */
  client: string;
  /**
   * External user id
   */
  user: string;
  updated_at: Date;
  created_at: Date;
}

export interface SmsInboundChannel extends BaseInboundChannel {
  type: DeliveryMethod.Sms;
  contact: {
    /**
     * international format = +{country_code}{number}
     */
    client_phone: string;
    /**
     * international format = +{country_code}{number}
     */
    user_phone: string;
  };
  context: {
    /**
     * conversation guid
     */
    guid: string;
  };
  token: null;
}

export interface EmailInboundChannel extends BaseInboundChannel {
  type: DeliveryMethod.Email;
  /**
   * some random token TODO: find out what it represents
   */
  token: string;
}

export type InboundChannel = SmsInboundChannel | EmailInboundChannel;

export interface TwilioWhatsAppApprovedTemplate {
  language: 'en';
  date_updated: string; // '2023-10-13T06:09:04Z';
  variables: Record<string, string>; //{ '1': 'Petar' }
  friendly_name: string; // 'inquiry_start';
  account_sid: string; // 'ACe890....';
  url: string; // 'https://content.twilio.com/v1/Content/HX25c27ca4666f3f3240caaf0c741b48bf';
  sid: string; // 'HX25c27ca4666f3f3240caaf0c741b48bf';
  date_created: string; // '2023-10-13T06:09:04Z';
  types: {
    'twilio/text': {
      // used for SMS
      body: string; // 'Hi, {{1}} \nThanks for contacting Fire University. How can I help?';
    };
    // used for whatsapp
    'twilio/quick-reply': {
      body: string; // 'Hi, {{1}} \nThanks for contacting Fire University. How can I help?';
      actions: { id: string; title: string }[];
      // [
      //   { id: 'application1'; title: 'Check application status' },
      //   { id: 'apply1'; title: 'Interested in applying' },
      //   { id: 'agentid1'; title: 'Speak with an agent' }
      // ];
    };
  };
  links: {
    approval_fetch: string; // 'https://content.twilio.com/v1/Content/HX25c27ca4666f3f3240caaf0c741b48bf/ApprovalRequests';
    approval_create: string; // 'https://content.twilio.com/v1/Content/HX25c27ca4666f3f3240caaf0c741b48bf/ApprovalRequests/whatsapp';
  };
}

export interface WhatsAppApprovedTemplate {
  id: string;
  message: string;
  variables: Record<string, string>;
  name: string;
}

export const WHATSAPP_SESSION_DURATION_HOURS = 24;

interface YoutubeThumbnail {
  url: string; // 'https://i.ytimg.com/vi/7HI_9nVF2LI/default.jpg';
  width: number; // 120;
  height: number; // 90;
}

interface YoutubeThumbnails {
  default: YoutubeThumbnail;
  medium: YoutubeThumbnail;
  high: YoutubeThumbnail;
}

interface YoutubeSnippet {
  publishedAt: string; // '2024-03-12T18:48:37Z';
  channelId: string; // 'UCY7mANrofT_Z4jE8imUCc4w';
  title: string; // 'Spring Career Expo 2024';
  description: string; // 'Dreaming of landing your dream job after college? At SEMO, not only do we prep you for your career, but we also help you land it!';
  thumbnails: YoutubeThumbnails;
  channelTitle: string; // 'Southeast Missouri State University';
  liveBroadcastContent: string; // 'none';
  publishTime: string; // '2024-03-12T18:48:37Z';
}

export interface YoutubeVideo {
  kind: 'youtube#searchResult';
  etag: string; // '8TgFBTwqD9J67YD-wpi5vtA9ppg';
  id: {
    kind: 'youtube#video';
    videoId: string; // '7HI_9nVF2LI';
  };
  snippet: YoutubeSnippet;
}

export interface YoutubeChannel {
  kind: 'youtube#searchResult';
  etag: string; // 't0UetnQ26G1oVRaR0U84H5GAufE';
  id: {
    kind: 'youtube#channel';
    channelId: string; // 'UCY7mANrofT_Z4jE8imUCc4w';
  };
  snippet: YoutubeSnippet;
}
