export interface Delay {
  offset: string;
}

export interface EmailContent {
  from_email: string;
  from_name: string;
  reply_email: string;
  subject: string;
  body: string;
  guid?: string;
}

export interface SmsContent {
  body: string;
  from_number: string;
}

export enum DeliveryChannel {
  Alert = 'alert',
  Email = 'email',
  SMS = 'sms'
}

export interface Message {
  index?: number;
  key?: string;
  guid?: string;
  name: string;
  active: boolean;
  delivery: DeliveryChannel[];
  email_content: EmailContent;
  sms_content: SmsContent;
}

export interface PreviewMethodParams {
  name: 'emails' | 'sms';
  value: string;
  preview: boolean;
}

export interface PreviewMethod {
  source: DeliveryChannel;
  parameters: PreviewMethodParams[];
}

export interface Preview {
  message: number | string;
  methods: PreviewMethod[];
}

export interface Token {
  label: string;
  value: string;
}
