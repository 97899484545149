export enum ValidationType {
  Accepted = 'accepted',
  After = 'after',
  Alpha = 'alpha',
  AlphaDash = 'alpha_dash',
  AlphaNum = 'alpha_num',
  AlphaSpace = 'alpha_space',
  Array = 'array',
  Before = 'before',
  Between = 'between',
  Boolean = 'boolean',
  Confirmed = 'confirmed',
  DateFormat = 'date_format',
  Different = 'different',
  Digits = 'digits',
  DigitsBetween = 'digits_between',
  Email = 'email',
  EmailVerify = 'email_verify',
  Exists = 'exists',
  Image = 'image',
  In = 'in',
  Integer = 'integer',
  Ip = 'ip',
  IsoDate = 'isodate',
  Max = 'max',
  Mimes = 'mimes',
  Min = 'min',
  Name = 'name',
  NotIn = 'not_in',
  Numeric = 'numeric',
  Password = 'password',
  Phone = 'phone',
  PhoneVerify = 'phone_verify',
  Regex = 'regex',
  RequiredCeeb = 'required_ceeb',
  Required = 'required',
  RequiredIf = 'required_if',
  RequiredWith = 'required_with',
  RequiredWithAll = 'required_with_all',
  RequiredWithout = 'required_without',
  RequiredWithoutAll = 'required_without_all',
  Same = 'same',
  Size = 'size',
  String = 'string',
  Ssn = 'ssn',
  Mask = 'mask',
  Timezone = 'timezone',
  Unique = 'unique',
  Url = 'url',
  ZipCode = 'zipcode'
}

export type ValidationFormat =
  | 'YYYY'
  | 'YYYY-MM'
  | 'YYYY-MM-DD'
  | 'YYYY-MM-DDThh:mmTZD'
  | 'YYYY-MM-DDThh:mm:ssTZD'
  | 'YYYY-MM-DDThh:mm:ss.sTZD';

export interface IValidation {
  date?: string;
  field?: string;
  /** csv of field slugs */
  fields?: string;
  /** dates use it */
  format?: ValidationFormat | string;
  /** csv */
  in?: string;
  length?: number | string;
  max?: number | string;
  min?: number | string;
  /** csv of mimes */
  mimes?: string;
  /** csv */
  not_in?: string;
  /** regexp string */
  pattern?: string;
  type: ValidationType | string;
  resource?: string;
  size?: number | string;
  /** mask pattern */
  mask?: string;
  slug?: string;
  /** csv */
  value?: string;
}
