import { Platform } from '@angular/cdk/platform';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API451_CLIENT, ApiClient } from '../api-client';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private apis = [this.api451Client.apiUrl, this.api451Client.apiUrlRT].filter(
    Boolean
  );

  constructor(
    @Inject(API451_CLIENT) public api451Client: ApiClient,
    private platform: Platform
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.api451Client.authorization;

    if (!auth) {
      return next.handle(request);
    }

    if (
      request.headers instanceof HttpHeaders &&
      request.headers.has('x-no-auth')
    ) {
      return next.handle(request);
    }

    if (this.isElementApiRequest(request)) {
      request = request.clone({
        setHeaders: {
          Authorization: auth
        }
      });
    }

    return next.handle(request);
  }

  private isElementApiRequest(request: HttpRequest<any>) {
    // this will ignore requests made for assets on the same origin
    if (request.url.startsWith('http')) {
      const host = new URL(request.url).host;
      return this.apis.some(api => api.includes(host));
    }

    return false;
  }
}
