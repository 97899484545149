import {
  doesUserHaveValidPhoneQuery,
  doesUserHaveValidPhoneQueryVariables,
  User
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  User,
  doesUserHaveValidPhoneQuery,
  doesUserHaveValidPhoneQueryVariables
};

export const userValidPhoneQuery = gql`
  query doesUserHaveValidPhone($userId: String!) {
    doesUserHaveValidPhone(input: { userId: $userId })
  }
`;
