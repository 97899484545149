import { NgModule } from '@angular/core';
import { Letters451Module } from '@element451-libs/letters451';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import {
  AccountExistsRegistrationDialogModule,
  AlertDialogModule,

  /** submit flow */
  ApplicationReadyDialogModule,
  CheckEmailDialogModule,
  CreateAccountDialogModule,
  ForgotPasswordDialogModule,
  InvalidPasswordResetTokenDialogModule,
  NotificationsOverlayModule,
  PaymentConfirmationDialogModule,
  PaymentDialogModule,
  RegistrationFormDialogModule,
  RejectOfferDialogModule,
  ResendConfirmDialogModule,
  ResetPasswordConfirmationDialogModule,
  ResetPasswordDialogModule,
  SignInDialogModule,
  StartApplicationDialogModule,
  SubmitConfirmationDialogModule,
  SubmitDialogModule,
  TipsAndInfoSidebarModule
} from '../components';
import { AccountStoreModule } from './account';
import { AnalyticsEffects } from './analytics';
import { AppEffects } from './app.effects';
import { metaReducers } from './app.metareducers';
import { appReducerProvider, appReducerToken } from './app.reducers';
import { AppService } from './app.service';
import { ApplicationStateService } from './application-state';
import { ChecklistEffects, ChecklistService } from './checklist';
import { CreateAccount, CreateAccountEffects } from './create-account';
import { DashboardEffects, DashboardService } from './dashboard';
import { DashboardRoutingService } from './dashboard-routing';
import { DocumentsEffects, DocumentsService } from './documents';
import { EditProfileEffects, EditProfileService } from './edit-profile';
import { Forms, FormsEffects } from './forms';
import {
  GeneratePreviewEffects,
  GeneratePreviewService
} from './generate-preview';
import { HeroService } from './hero';
import { InfoRequestEffects, InfoRequestService } from './info-request';
import { LettersEffects, LettersService } from './letters';
import { MessengerService } from './messenger';
import { PaymentEffects } from './payment';
import { ProgressEffects, ProgressService } from './progress';
import { RecommendEffects, RecommendService } from './recommend';
import { RegistrationForm, RegistrationFormEffects } from './registration-form';
import { SchoolService } from './school';
import { SiteService } from './site';
import { SnapApp, SnapAppEffects } from './snap-app';
import { SocialLoginDataEffects } from './social-login-data';
import { StartApplication, StartApplicationEffects } from './start-application';
import { Steps, STEPS_EFFECTS } from './steps';
import {
  SubmitApplicationEffects,
  SubmitApplicationService,
  SubmitFormService
} from './submit';
import {
  SupplementalFormsEffects,
  SupplementalFormsService
} from './supplemental-forms';
import { TipsAndInfoEffects, TipsAndInfoService } from './tips-and-info';
import { TokensEffects, TokensService } from './tokens';
import { UnregisteredUserData } from './unregistered-user-data';
import { UserApplications, UserApplicationsEffects } from './user-applications';
import { UserData, UserDataEffects } from './user-data';
import { UserDocumentsEffects, UserDocumentsService } from './user-documents';
import {
  UserInfoRequestsEffects,
  UserInfoRequestsService
} from './user-info-requests';

const STORE_UI_MODULES = [
  AccountExistsRegistrationDialogModule,
  AlertDialogModule,
  CheckEmailDialogModule,
  CreateAccountDialogModule,
  ForgotPasswordDialogModule,
  InvalidPasswordResetTokenDialogModule,
  NotificationsOverlayModule,
  RegistrationFormDialogModule,
  ResendConfirmDialogModule,
  ResetPasswordConfirmationDialogModule,
  ResetPasswordDialogModule,
  SignInDialogModule,
  StartApplicationDialogModule,
  ApplicationReadyDialogModule,
  PaymentDialogModule,
  PaymentConfirmationDialogModule,
  SubmitConfirmationDialogModule,
  SubmitDialogModule,
  TipsAndInfoSidebarModule,
  RejectOfferDialogModule
];

export const EFFECTS = [
  AppEffects,
  AnalyticsEffects,
  CreateAccountEffects,
  ChecklistEffects,
  DashboardEffects,
  DocumentsEffects,
  EditProfileEffects,
  FormsEffects,
  RegistrationFormEffects,
  GeneratePreviewEffects,
  InfoRequestEffects,
  LettersEffects,
  ProgressEffects,
  RecommendEffects,
  SocialLoginDataEffects,
  StartApplicationEffects,
  SubmitApplicationEffects,
  SnapAppEffects,
  ...STEPS_EFFECTS,
  TipsAndInfoEffects,
  TokensEffects,
  UserApplicationsEffects,
  UserDocumentsEffects,
  UserDataEffects,
  UserInfoRequestsEffects,
  PaymentEffects,
  SupplementalFormsEffects
];

export const SERVICES = [
  AppService,
  CreateAccount,
  ApplicationStateService,
  DashboardService,
  DashboardRoutingService,
  DocumentsService,
  ChecklistService,
  EditProfileService,
  Forms,
  GeneratePreviewService,
  HeroService,
  InfoRequestService,
  LettersService,
  RecommendService,
  SchoolService,
  SiteService,
  StartApplication,
  UserInfoRequestsService,
  UserApplications,
  UserDocumentsService,
  Steps,
  UserData,
  MessengerService,
  ProgressService,
  SnapApp,
  SubmitApplicationService,
  SubmitFormService,
  TipsAndInfoService,
  TokensService,
  UnregisteredUserData,
  RegistrationForm,
  SupplementalFormsService
];

@NgModule({
  imports: [
    // Store setup
    StoreModule.forRoot(appReducerToken, {
      metaReducers: !environment.production ? metaReducers : [],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),

    EffectsModule.forRoot(EFFECTS),
    AccountStoreModule.forRoot(),
    Letters451Module.forRoot(),

    STORE_UI_MODULES
  ],
  providers: [SERVICES, appReducerProvider]
})
export class AppStoreModule {}
