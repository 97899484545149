import { ApplicationsApi } from '@element451-libs/models451';
import { parseDate } from '@element451-libs/utils451/dates';
import { get } from '@element451-libs/utils451/get';
import { replaceAll, sortCollection } from '@element451-libs/utils451/helpers';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isFuture } from 'date-fns';
import { Dictionary, isBoolean, isNil } from 'lodash';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { selectApp } from '../app.feature';
import { getDashboardStatus } from '../status';
import * as actions from './dashboard.actions';
import { DashboardAction, DASHBOARD_ACTIONS } from './dashboard.actions';
import { Dashboard, DashboardStep } from './dashboard.models';

export interface DashboardState extends EntityState<Dashboard> {
  loading: boolean;
  welcomeOpened: boolean;
  hasUnsavedFormData: boolean;
}

const selectId = (dash: Dashboard) => dash.registrationId;

const adapter = createEntityAdapter<Dashboard>({
  selectId: selectId,
  sortComparer: false
});

const initialState = adapter.getInitialState({
  loading: false,
  welcomeOpened: false,
  hasUnsavedFormData: false
});

export const dashboardFeature = 'dashboard';

export function dashboardReducer(
  state: DashboardState = initialState,
  action: DashboardAction | fromAccount.AccountAction
): DashboardState {
  switch (action.type) {
    case DASHBOARD_ACTIONS.LOAD_DASHBOARD_REQUEST: {
      const loaded = isLoaded(state, action.payload);
      return loaded ? state : { ...state, loading: true };
    }

    case DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS: {
      state = adapter.addOne(action.payload.normalized, state);
      return {
        ...state,
        loading: false
      };
    }

    case DASHBOARD_ACTIONS.LOAD_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false
      };

    case DASHBOARD_ACTIONS.APPLICATION_SUBMITTED:
      return setSubmittedStatus(state, action);

    case DASHBOARD_ACTIONS.DECLINE_OFFER_SUCCESS:
      return setWithdrawnStatus(state, action);

    case DASHBOARD_ACTIONS.APPLICATION_PAID:
      return setPaidStatus(state, action);

    case DASHBOARD_ACTIONS.DEPOSIT_PAID:
      return setDepositCompleted(state, action);

    case DASHBOARD_ACTIONS.OPENED_WELCOME_PAGE:
      return { ...state, welcomeOpened: true };

    case DASHBOARD_ACTIONS.CLOSED_WELCOME_PAGE:
      return { ...state, welcomeOpened: false };

    case ACCOUNT_ACTIONS.SIGN_OUT:
      return initialState;

    case DASHBOARD_ACTIONS.HAS_UNSAVED_FORM_DATA:
      return { ...state, hasUnsavedFormData: action.payload };

    default:
      return state;
  }
}

const _selectDashboardState =
  createFeatureSelector<DashboardState>(dashboardFeature);

export const selectDashboardState = createSelector(
  selectApp,
  _selectDashboardState
);

export const selectIsDashboardLoading = createSelector(
  selectDashboardState,
  state => state.loading
);

/**
 *
 */

const sortSteps = sortCollection<DashboardStep>(
  (a, b) => a.index_weight - b.index_weight
);
const _selectSteps = (dash: Dashboard) => dash.steps;
export const selectSteps = createSelector(_selectSteps, sortSteps);

export const selectProgressGuid = (dash: Dashboard) => dash.progress_guid;

export const selectSubmitFormGuid = (dash: Dashboard) => dash.submit_form_guid;

export const selectAppPayment = (dash: Dashboard) => dash.payment;

export const selectDepositPayment = (dash: Dashboard) => {
  return dash.deposit || ({} as ApplicationsApi.DepositPayment);
};

export const selectSidebarContent = (dash: Dashboard) => dash.sidebar_content;

export const showCompletedAlert = (dash: Dashboard) => dash.completed_alert;

export const selectAllowSnapApp = (dash: Dashboard) => dash.snap_app;

export const selectIsSnapAppDefault = (dash: Dashboard) =>
  dash.defaultView === 'snap';

export const selectRegistrationId = (dashboard: Dashboard) =>
  dashboard.registrationId;

export const selectApplicationGuid = (dashboard: Dashboard) => dashboard.guid;

export const selectHeroes = (dash: Dashboard) => dash.hero;

export const selectShowInfoRequestStep = (dash: Dashboard) =>
  dash?.info_request?.show;

export const selectInfoBlocks = (dash: Dashboard) => dash.info_blocks;

export const selectMajor = (dash: Dashboard) =>
  get(dash, 'major', 'guid') || null;

export const selectTerm = (dash: Dashboard) =>
  get(dash, 'term', 'guid') || null;

/**
 *
 */

const _selectApplicationStatusObject = (dash: Dashboard) => dash.status;

const _selectDecisionStatusObject = (dash: Dashboard) => dash.decision_status;

export const selectIsApplicationSubmitted = createSelector(
  _selectApplicationStatusObject,
  status => status && status.submitted
);

export const selectDecisionStatus = createSelector(
  _selectDecisionStatusObject,
  status => status.slug
);

export const selectApplicationStatus = createSelector(
  _selectApplicationStatusObject,
  status => status.status
);

export const selectApplicationStatusChanged = createSelector(
  _selectApplicationStatusObject,
  status => status.status_changed_at
);

export const selectApplicationStatusUpdatedAt = createSelector(
  _selectApplicationStatusObject,
  status => status.updated_at
);

export const selectApplicationRegisteredAt = createSelector(
  _selectApplicationStatusObject,
  status => status.registered_at
);

const DECIDED_STATUSES = [
  ApplicationsApi.ApplicationStatusType.Admitted,
  ApplicationsApi.ApplicationStatusType.Denied,
  ApplicationsApi.ApplicationStatusType.ConditionalOffer,
  ApplicationsApi.ApplicationStatusType.Deferred,
  ApplicationsApi.ApplicationStatusType.Waitlisted
];

const POSITIVE_OUTCOME = [
  ApplicationsApi.ApplicationStatusType.Admitted,
  ApplicationsApi.ApplicationStatusType.ConditionalOffer
];

export const selectHasDecision = createSelector(selectDecisionStatus, status =>
  DECIDED_STATUSES.includes(status)
);

export const selectReviewStartedDate = createSelector(
  _selectDecisionStatusObject,
  status => status.review_starts_at
);

export const selectDecisionMadeDate = createSelector(
  _selectDecisionStatusObject,
  status => status.decision_made_at
);

export const selectSubmittedDate = createSelector(
  _selectApplicationStatusObject,
  status => status.submitted_at
);

export const selectDashboardStatus = createSelector(
  _selectApplicationStatusObject,
  _selectDecisionStatusObject,
  getDashboardStatus
);

export const selectIsAdmitted = createSelector(selectDecisionStatus, status =>
  POSITIVE_OUTCOME.includes(status)
);

export const selectIsWithdrawn = createSelector(
  selectDecisionStatus,
  status => status === ApplicationsApi.ApplicationStatusType.Withdrawn
);

export const selectIsRejectionEnabled = (dash: Dashboard) =>
  dash.decision_confirmation;

export const selectSubmittedStatusText = (dash: Dashboard) => {
  // for backwards compatibility
  if (isNil(dash.submitted_status_text)) {
    return `Please complete all the required items from the checklist so we can start reviewing your application.`;
  }
  return dash.submitted_status_text;
};

export const selectShowApplicationStatus = (dash: Dashboard) => {
  if (isBoolean(dash.show_application_status)) {
    return dash.show_application_status;
  }
  // for backwards compatibility
  return true;
};

export const selectShowDocumentsSection = (dash: Dashboard) => {
  if (isBoolean(dash.show_documents_section)) {
    return dash.show_documents_section;
  }
  // for backwards compatibility
  return true;
};

/**
 *
 */

export const isAppPaymentRequiredBeforeSubmission = createSelector(
  selectAppPayment,
  payment => payment.required_to_submit
);

export const isAppPaymentCompleted = createSelector(
  selectAppPayment,
  payment => payment.completed
);

export const isDepositPaymentCompleted = createSelector(
  selectDepositPayment,
  payment => get(payment, 'payment', 'completed') || false
);

export const selectDepositPaymentTime = createSelector(
  selectDepositPayment,
  payment => get(payment, 'payment', 'time') || null
);

export const selectDepositPaymentId = createSelector(
  selectDepositPayment,
  payment => payment._id
);

export const selectHasDeposit = createSelector(
  selectDepositPayment,
  selectIsAdmitted,
  isDepositPaymentCompleted,
  (deposit, isAdmitted, depositPaid) =>
    deposit.active && isAdmitted && !depositPaid
);

export const selectDepositContext = createSelector(
  selectDepositPaymentId,
  selectMajor,
  selectTerm,
  selectRegistrationId,
  selectApplicationGuid,
  (depositId, majorGuid, termGuid, registrationId, applicationGuid) => ({
    depositId,
    context: {
      majorGuid,
      termGuid,
      registrationId,
      applicationGuid
    }
  })
);

const HeroStatusMapping = {
  admitted: ApplicationsApi.ApplicationStatusType.Admitted,
  denied: ApplicationsApi.ApplicationStatusType.Denied,
  'conditional-offer': ApplicationsApi.ApplicationStatusType.ConditionalOffer,
  deferred: ApplicationsApi.ApplicationStatusType.Deferred,
  waitlisted: ApplicationsApi.ApplicationStatusType.Waitlisted
};
export const selectHeroesAsTable = createSelector(selectHeroes, heroes =>
  heroes.reduce((table, hero) => {
    const mapping = HeroStatusMapping[hero.status];
    if (mapping) {
      table[mapping] = hero;
    } else {
      table[hero.status] = hero;
    }

    return table;
  }, {} as Dictionary<ApplicationsApi.Hero>)
);

export const selectWallCards = createSelector(
  selectIsApplicationSubmitted,
  selectInfoBlocks,
  (isSubmitted, infoBlocks) => {
    if (!isSubmitted) {
      return infoBlocks.filter(
        a => a.type !== ApplicationsApi.InfoBlockType.Checklist
      );
    }
    return infoBlocks;
  }
);

const selectValidWallCards = createSelector(selectWallCards, wallCards =>
  wallCards.reduce((cards, card) => {
    switch (card.type) {
      case ApplicationsApi.InfoBlockType.Deadline:
        {
          const dates = (card.dates || []).filter(date =>
            isFuture(parseDate(date.date))
          );

          if (dates.length > 0) {
            card = { ...card, dates };
            cards.push(card);
          }
        }
        break;

      default:
        cards.push(card);
        break;
    }

    return cards;
  }, [] as ApplicationsApi.InfoBlock[])
);

const sortWallCards = sortCollection<ApplicationsApi.InfoBlock>((a, b) =>
  a.index_weight < b.index_weight ? -1 : 1
);

export const selectSortedValidWallCards = createSelector(
  selectValidWallCards,
  sortWallCards
);

export const isWelcomePageOpened = createSelector(
  selectDashboardState,
  state => state.welcomeOpened
);

export const { selectEntities } = adapter.getSelectors(selectDashboardState);

function isLoaded(state: DashboardState, id: string): boolean {
  return !!state.entities[id];
}

function setSubmittedStatus(
  state: DashboardState,
  action: actions.ApplicationSubmittedAction
): DashboardState {
  const { registrationId } = action.payload;
  const dashboard = state.entities[registrationId];

  const update = {
    id: registrationId,
    changes: {
      status: {
        ...dashboard.status,
        submitted: true,
        submitted_at: new Date().toISOString()
      },
      decision_status: {
        name: 'Submitted',
        slug: ApplicationsApi.ApplicationStatusType.Submitted
      } as ApplicationsApi.DecisionStatusSubmitted
    } as Partial<Dashboard>
  };

  return adapter.updateOne(update, state);
}

function setWithdrawnStatus(
  state: DashboardState,
  action: actions.DeclineOfferSuccessAction
): DashboardState {
  const { registrationId } = action.payload;
  const update = {
    id: registrationId,
    changes: {
      decision_status: {
        name: 'Withdrawn',
        slug: ApplicationsApi.ApplicationStatusType.Withdrawn
      } as ApplicationsApi.DecisionStatusWithdrawn
    } as Partial<Dashboard>
  };
  return adapter.updateOne(update, state);
}

function setPaidStatus(
  state: DashboardState,
  action: actions.ApplicationPaidAction
): DashboardState {
  const { registrationId } = action.payload;
  const dashboard = state.entities[registrationId];

  const update = {
    id: registrationId,
    changes: {
      payment: {
        ...dashboard.payment,
        completed: true
      }
    }
  };

  return adapter.updateOne(update, state);
}

function setDepositCompleted(
  state: DashboardState,
  action: actions.DepositPaidAction
): DashboardState {
  const { registrationId } = action.payload;
  const dashboard = state.entities[registrationId];

  const update = {
    id: registrationId,
    changes: {
      deposit: {
        ...dashboard.deposit,
        payment: {
          completed: true,
          time: new Date().toISOString()
        }
      }
    }
  };

  return adapter.updateOne(update, state);
}

export const selectDecisionStatusNormalized = createSelector(
  selectDecisionStatus,
  status => {
    // heroes don't support 'to-review' and 'in-progress'
    // instead we set it to in-review
    if (
      status === ApplicationsApi.ApplicationStatusType.ToReview ||
      status === ApplicationsApi.ApplicationStatusType.InProgress
    ) {
      return ApplicationsApi.ApplicationStatusType.InReview;
    }
    return status;
  }
);

export const selectApplicationHero = createSelector(
  selectHeroesAsTable,
  selectDecisionStatusNormalized,
  (heroesTable, status) => {
    // special case, api does not expose this on greeting messages
    if (status === ApplicationsApi.ApplicationStatusType.Withdrawn) {
      return {
        title: '',
        description: '',
        status
      } as ApplicationsApi.Hero;
    }

    const hero = heroesTable[status];
    return hero || heroesTable['default'];
  }
);

const TOKENS = /\[.\S+\]/gm;

export const selectHeroTokens = createSelector(selectHeroes, heroes => {
  const texts = [];
  for (const hero of heroes) {
    texts.push(hero.title, hero.description);
    const congratulations = (hero as ApplicationsApi.PositiveOutcomeHero)
      .congratulations;

    if (congratulations) {
      texts.push(
        congratulations.button_label,
        congratulations.message,
        congratulations.subtitle,
        congratulations.title
      );
    }
  }

  const tokens = new Set<string>();

  const matched = texts.filter(Boolean).join(' ').match(TOKENS) || [];
  for (const match of matched) tokens.add(match);

  return Array.from(tokens);
});

export const selectHasUnsavedFormData = createSelector(
  selectDashboardState,
  state => state.hasUnsavedFormData
);

export const selectCongratulationsLetter = createSelector(
  selectRegistrationId,
  selectIsAdmitted,
  selectApplicationHero,
  (
    registrationId,
    isAdmitted: boolean,
    hero: ApplicationsApi.PositiveOutcomeHero
  ) => {
    const congratulations = isAdmitted ? hero.congratulations : null;
    if (congratulations) {
      return {
        ...congratulations,
        button_link: replaceRegistrationId(
          congratulations.button_link,
          registrationId
        )
      };
    }

    return congratulations;
  }
);

function replaceRegistrationId(text: string, registrationId: string) {
  return replaceAll(text, '[application:registration_id]', registrationId);
}
export const selectCanGoToSnapStep = createSelector(
  selectIsApplicationSubmitted,
  selectAllowSnapApp,
  (isApplicationSubmitted, isAllowed) => !isApplicationSubmitted && isAllowed
);

export const selectShowSnapAppInitially = createSelector(
  selectCanGoToSnapStep,
  selectIsSnapAppDefault,
  (canGoToSnapStep, isSnapStepDefault) => canGoToSnapStep && isSnapStepDefault
);
