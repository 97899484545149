import { Inject, Injectable } from '@angular/core';

import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { getSubdomainFromUrl } from '../core';
import { rootUrl } from '../shared';

import { OAUTH_GOOGLE_CLIENT_ID } from './oauth-client-ids';
import { OAuthModule } from './oauth.module';

export const enum OAUTH_TYPES {
  EMAIL = 'email', // primarily used on App451V3
  GOOGLE = 'google'
}

export const enum OAUTH_SOURCE_APPS {
  DASHBOARD = 'dashboard451',
  SITE = 'site451',
  APP = 'app451',
  PORTAL = 'portal451'
}

export interface SocialLoginUser {
  provider: string;
  id: string;
  email: string;
  name: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
  authToken: string;
  idToken: string;
}

@Injectable({
  providedIn: OAuthModule
})
export class OAuthService {
  constructor(
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    @Inject(OAUTH_GOOGLE_CLIENT_ID) private oauthGoogleClientId: string
  ) {}

  private getRedirectUri(sourceApp: string): string {
    let redirectUri = this.url('');

    redirectUri = redirectUri.replace(
      getSubdomainFromUrl(this.url('')) + '.',
      ''
    );

    if (sourceApp === OAUTH_SOURCE_APPS.DASHBOARD) {
      // used ONLY on Dashboard V2 (new API route)
      redirectUri += 'login/social/callback';
    } else {
      // used on Site451/App451V3 (old API route)
      redirectUri += 'social/callback';
    }

    return redirectUri;
  }

  private getBaseState(type: string, sourceApp: string): string {
    const stateJson = {
      subdomain: getSubdomainFromUrl(this.url('')),
      base_url: window.location.origin + '/', // old API route require slash on the end
      provider: '',
      query: window.location.search,
      source_app: sourceApp
    };

    if (
      type === OAUTH_TYPES.GOOGLE &&
      sourceApp === OAUTH_SOURCE_APPS.DASHBOARD
    ) {
      stateJson.base_url = stateJson.base_url + 'v2/login';
    }

    switch (type) {
      case OAUTH_TYPES.GOOGLE: {
        stateJson.provider = OAUTH_TYPES.GOOGLE;
        break;
      }
    }

    return window.btoa(JSON.stringify(stateJson));
  }

  /**
   * Sign In With Google (OAuth 2.0)
   */
  public signInWithGoogle(sourceApp: string) {
    const googleAuthUri = 'https://accounts.google.com/o/oauth2/auth';

    const googleAuthParameters = [
      // Required. The OAuth 2.0 client ID for your application. You can find this value in the Developers Console.
      `client_id=${this.oauthGoogleClientId}`,

      // Required. A registered redirect_uri for your client ID.
      // Register valid redirect URIs for your application in the Developers Console.
      `redirect_uri=${this.getRedirectUri(sourceApp)}`,

      // Required. Determines whether the Google OAuth 2.0 endpoint returns an authorization code. Set the parameter's value to code.
      `response_type=code`,

      // Required. A space-delimited list of scopes that identify the resources that your application could access on the user's
      // behalf. These values determine which permissions are listed on the consent page that Google displays to the user.
      `scope=email`,

      // Recommended. This parameter indicates whether your application can refresh access tokens when the user is not present
      // at the browser. Valid parameter values are online and offline. Set this parameter value to offline to allow
      // the application to use refresh tokens when the user is not present.
      `access_type=offline`,

      // Optional. A string that your application uses to maintain state between the request and redirect response.
      // The exact value that you send is returned as a name=value pair in the hash (#) fragment of the redirect_uri
      // after the user consents to or denies your application's access request.
      // You could use this parameter for several purposes, such as directing the user to the correct resource in your application,
      // sending nonces, and mitigating cross-site request forgery.
      `state=${this.getBaseState(OAUTH_TYPES.GOOGLE, sourceApp)}`
    ];
    const googleAuthUriWithParameters =
      googleAuthUri + '?' + googleAuthParameters.join('&');

    window.location.href = googleAuthUriWithParameters;
  }

  /**
   * Sign In With SSO (SAML 2.0)
   */
  public signInWithSso(saml2IdpKey: string) {
    const _url = this.url(`auth/saml2/${saml2IdpKey}/login`);

    window.location.href =
      rootUrl(_url) +
      `/?return_to=${encodeURIComponent(window.location.origin + '/')}`;
  }
}
