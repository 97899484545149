export interface Webhook {
  guid: string;
  name: string;
  config: WebhookConfig;
  throttle_config: WebhookThrottleConfig;
  updated_at: string;
  created_at: string;
}

export interface WebhookDto {
  name: string;
  url: string;
  method: WebhookMethod;
  headers: WebhookConfigHeader[];
  webhook_body_type: WebhookPayloadType;
  payload: string | null; // if type === raw_json
  payload_template_guid: string | null; // if type === template
  requests_limit: number;
}

export interface WebhookConfigHeader {
  name: string;
  value: string;
}

export interface WebhookThrottleConfig {
  requests_limit?: number;
}

export interface WebhookConfig {
  url: string;
  method: WebhookMethod;
  headers: WebhookConfigHeader[];
  webhook_body_type: WebhookPayloadType;
  payload: string | null; // if type === raw_json
  payload_template_guid: string | null; // if type === template
}

export enum WebhookPayloadType {
  JSON = 'raw_json',
  Template = 'template'
}

export enum WebhookMethod {
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

export const WEBHOOK_METHODS = Object.entries(WebhookMethod).map(
  ([label, value]) => ({
    label,
    value
  })
);

export enum WebhookExecutionStatus {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed'
}

export interface WebhookExecutionTryResult {
  status: number;
  response: string;
  created_at: string;
  guid: string;
}

export interface WebhookExecution {
  _id: string;
  status: WebhookExecutionStatus;
  webhook_guid: string;
  try_count: number;
  try_results: WebhookExecutionTryResult[];
  related_to?: string;
  execute_at: string;
  updated_at: string;
  created_at: string;
  payload: string;
}

export interface WebhookExecutionResponse {
  response_status_code: number;
  response_headers: string[];
  response_body: string;
}
