import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  Background,
  GRID,
  mixinBackground,
  mixinPadding,
  mixinResponsive,
  mixinTheme,
  PaddingType,
  Page451Component,
  Theme,
  translationsProvider
} from '../core';
import {
  Page451EditableGroupService,
  PAGE_COMPONENT,
  PAGE_CONFIG
} from '../editor';
import { discoveryConfigFactory } from './discovery.config';
import { IPgDiscovery, IPgDiscoveryStarter } from './discovery.interface';

export class DiscoveryBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _DiscoveryBase = mixinPadding(
  mixinResponsive(mixinBackground(mixinTheme(DiscoveryBase, 'light'))),
  {
    top: '120px',
    bottom: '120px'
  }
);

@Component({
  selector: 'elm-pg-discovery',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: discoveryConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => DiscoveryComponent)
    },
    Page451EditableGroupService
  ],
  viewProviders: [translationsProvider]
})
export class DiscoveryComponent
  extends _DiscoveryBase
  implements Page451Component, IPgDiscovery
{
  _starters: IPgDiscoveryStarter[] = [];

  @Input() pageGuid;
  @Input() title: string;
  @Input() theme: Theme;
  @Input() background: Background;
  @Input() padding: PaddingType;

  @Input()
  set starters(starters: IPgDiscoveryStarter[]) {
    this._starters = starters.sort((a, b) => a.index_weight - b.index_weight);
  }

  @Input()
  inputContainerBackgroundColor?: string;
  @Input()
  inputPlaceholder?: string;
  @Input()
  inputBorderColor?: string;

  @Output()
  startThread = new EventEmitter<string>();

  @HostBinding('class.is-mobile-layout') isLtMd;

  @ViewChild('searchInput', { static: true })
  searchInput!: ElementRef;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID
  ) {
    super(renderer, elementRef, media, cd);
  }

  trackByStarterId = (index: number, item: IPgDiscoveryStarter) => item._id;

  startThreadHandler(message: string) {
    if (message) {
      this.startThread.emit(message);
      this.searchInput.nativeElement.value = '';
    }
  }
}
