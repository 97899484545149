import { Inject, Injectable } from '@angular/core';
import {
  API451_CLIENT,
  ApiClient,
  ApplicationsApiService,
  responseData
} from '@element451-libs/api451';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { AccountService } from '../account';
import * as fromRecommend from './recommend.actions';
import { RECOMMEND_ACTIONS } from './recommend.actions';

@Injectable()
export class RecommendEffects {
  constructor(
    private actions$: Actions<fromRecommend.RecommendAction>,
    private applicationsApiService: ApplicationsApiService,
    private account: AccountService,
    @Inject(API451_CLIENT) private api451Client: ApiClient
  ) {}

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RECOMMEND_ACTIONS.ADD_REQUEST),
      mapToPayload,
      concatMap(payload =>
        this.applicationsApiService.addRecommendation(payload).pipe(
          responseData,
          map(data => new fromRecommend.AddSuccessAction(data)),
          catchError(err => of(new fromRecommend.AddFailAction(err)))
        )
      )
    )
  );

  removeFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RECOMMEND_ACTIONS.REMOVE_FILE_REQUEST),
      mapToPayload,
      concatMap(({ fieldName }) =>
        this.applicationsApiService.removeRecommendationFile(fieldName).pipe(
          map(() => new fromRecommend.RemoveFileSuccessAction({ fieldName })),
          catchError(error =>
            of(new fromRecommend.RemoveFileFailAction({ fieldName, error }))
          )
        )
      )
    )
  );

  onAddRefetchRecommendation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RECOMMEND_ACTIONS.ADD_SUCCESS),
        mapToPayload,
        tap(() => this.refetchRecommendation())
      ),
    { dispatch: false }
  );

  refetchRecommendation() {
    /**
     * recommendation data is in locker
     */
    this.account.reloginWithLocker(this.api451Client.authorization);
  }
}
