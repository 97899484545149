import {
  TasksApi_GetTasksTemplateQuery as TasksTemplate,
  TasksApi_GetTasksTemplateQueryVariables as TasksTemplateVariables
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export const getTasksTemplate = gql<TasksTemplate, TasksTemplateVariables>`
  query TasksApi_GetTasksTemplate($guid: String!) {
    tasksTemplate(input: { guid: $guid }) {
      guid
      name
      description
      type_guid
      status_guid
      priority_guid
      due_datetime_type
      due_datetime
      due_days
      assigned_to
      subscribers
      parent_task_guid
      subtasks {
        guid
        name
        due_datetime_type
        due_datetime
        due_days
        type_guid
        status_guid
        priority_guid
        assigned_to
        subscribers
        parent_task_guid
      }
      created_by
      created_at
      updated_at
    }
  }
`;
