import { WebhooksApi_GetWebhooksQuery as GetWebhooks } from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export { GetWebhooks };

export const getWebhooks = gql`
  query WebhooksApi_GetWebhooks {
    webhooks {
      guid
      name
      config {
        url
        method
        webhook_body_type
        payload
        payload_template_guid
        headers {
          name
          value
        }
      }
      throttle_config {
        requests_limit
      }
      updated_at
      created_at
    }
  }
`;
