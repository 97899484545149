import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  API451_CLIENT,
  ApiClient,
  ApplicationsApi,
  AuthApi,
  AuthApiService,
  FormsApi,
  UserApi,
  UserApiService,
  encodeAuth,
  responseData
} from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { IFieldWithData } from '@element451-libs/forms451';
import { DocumentRef } from '@element451-libs/utils451/document';
import { get } from '@element451-libs/utils451/get';
import { mapToPayload, truthy } from '@element451-libs/utils451/rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { find } from 'lodash';
import { defer, merge, of, race } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  AccountExistsRegistrationDialogComponent,
  AlertDialogComponent,
  CHECK_EMAIL_DIALOG_ACTIONS,
  CheckEmailDialogComponent,
  FORGOT_PASSWORD_DIALOG_ACTIONS,
  ForgotPasswordDialogComponent,
  InvalidPasswordResetTokenDialogComponent,
  NotificationsOverlayService,
  ResetPasswordConfirmationDialogComponent,
  ResetPasswordDialogComponent,
  SIGN_IN_DIALOG_ACTIONS,
  SignInDialogComponent,
  SignInDialogData
} from '../../components';
import * as fromSite from '../site/site.actions';
import { SiteService } from '../site/site.service';
import { UserApplications } from '../user-applications';
import * as fromUserApplications from '../user-applications/user-applications.actions';
import * as fromAccount from './account.actions';
import { ACCOUNT_ACTIONS } from './account.actions';
import { AccountService } from './account.service';

export function callbackUrl() {
  return window ? window.location.origin : '';
}

const accountDialogConfig = {
  maxWidth: '466px'
};

@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions<fromAccount.AccountAction>,
    private store: Store,
    private accountService: AccountService,
    private authApiService: AuthApiService,
    private userApiService: UserApiService,
    @Inject(API451_CLIENT) public api451Client: ApiClient,
    private documentRef: DocumentRef,
    private dialog: ElmDialogService,
    private site: SiteService,
    private router: Router,
    private transloco: TranslocoService,
    private userApplications: UserApplications,
    private notifications: NotificationsOverlayService
  ) {}

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.SIGN_OUT),
        tap(_ => {
          this.clearPersistedSession();
          this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  failSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.SIGN_IN_FAIL),
      map(_ => new fromAccount.SignOutAction())
    )
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.SIGN_IN_REQUEST),
      mapToPayload,
      tap(({ authentication }) => this.setOneTimeSession(authentication)),
      switchMap(({ authentication }) =>
        this.authApiService.externalLogin().pipe(
          responseData,
          map(response => {
            if (AuthApi.isMfaLoginResponse(response)) {
              return new fromAccount.MfaAuthLoginRequestAction({
                authentication
              });
            }
            return new fromAccount.BasicAuthLoginSuccessAction(response.token);
          }),
          catchError(err => of(new fromAccount.SignInFailAction(err)))
        )
      )
    )
  );

  confirmMfaCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CONFIRM_MFA_LOGIN_CODE),
      mapToPayload,
      switchMap(({ code }) =>
        this.authApiService.externalLogin(code).pipe(
          responseData,
          map(({ token }) => new fromAccount.MfaAuthLoginSuccessAction(token)),
          catchError(err => of(new fromAccount.MfaAuthLoginFailAction(err)))
        )
      )
    )
  );

  signInAfterRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.SIGN_IN_AFTER_REGISTRATION),
        mapToPayload,
        withLatestFrom(this.accountService.isAuthorized$),
        filter(([_, isAuthorized]) => !isAuthorized),
        switchMap(([{ email, password }]) =>
          this.authApiService.externalLogin().pipe(
            responseData,
            tap(response => {
              if (AuthApi.isMfaLoginResponse(response)) {
                this.store.dispatch(
                  new fromAccount.OpenSignInDialogAction({ email, password })
                );
                this.store.dispatch(
                  new fromAccount.MfaAuthLoginRequestAction({
                    authentication: encodeAuth({ email, password })
                  })
                );
              } else {
                this.store.dispatch(
                  new fromAccount.BasicAuthLoginSuccessAction(response.token)
                );
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  openDashboardAfterRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.SIGN_IN_AFTER_REGISTRATION),
        mapToPayload,
        switchMap(({ registrationId }) =>
          this.userApplications
            .selectLoadedByRegistrationId$(registrationId)
            .pipe(
              truthy,
              take(1),
              map(() => registrationId)
            )
        ),
        tap(registrationId => {
          this.router.navigate(['/dashboard', registrationId, 'welcome'], {
            queryParamsHandling: 'merge'
          });
        })
      ),
    { dispatch: false }
  );

  lockerUrlLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_REQUEST),
      mapToPayload,
      tap(_ => this.clearPersistedSession()),
      switchMap(token =>
        this.authApiService.loginLocker(token).pipe(
          responseData,
          map(
            account =>
              new fromAccount.LockerUrlLoginSuccessAction({
                token,
                data: account as ApplicationsApi.RequestLocker
              })
          ),
          catchError(err => of(new fromAccount.LockerUrlLoginFailAction(err)))
        )
      )
    )
  );

  lockerSessionLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_REQUEST),
      mapToPayload,
      tap(_ => this.clearPersistedSession()),
      switchMap(token =>
        this.authApiService.loginLocker(token).pipe(
          responseData,
          map(
            account =>
              new fromAccount.LockerSessionLoginSuccessAction({
                token,
                data: account as ApplicationsApi.RequestLocker
              })
          ),
          catchError(err =>
            of(new fromAccount.LockerSessionLoginFailAction(err))
          )
        )
      )
    )
  );

  authWithLocker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ACCOUNT_ACTIONS.BASIC_AUTH_LOGIN_SUCCESS,
        ACCOUNT_ACTIONS.MFA_AUTH_LOGIN_SUCCESS
      ),
      mapToPayload,
      switchMap(token =>
        this.authApiService.loginLocker(token).pipe(
          responseData,
          map(
            (data: ApplicationsApi.RequestLocker) =>
              new fromAccount.SignInSuccessAction({ token, data })
          ),
          catchError(err => of(new fromAccount.SignInFailAction(err)))
        )
      )
    )
  );

  lockerUrlLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_SUCCESS),
        mapToPayload,
        tap(payload => this._lockerLogin(payload, { isUrlLocker: true }))
      ),
    { dispatch: false }
  );

  lockerSessionLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_SUCCESS),
        mapToPayload,
        tap(payload => this._lockerLogin(payload))
      ),
    { dispatch: false }
  );

  lockerAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.SIGN_IN_SUCCESS),
        mapToPayload,
        tap(payload => this._lockerLogin(payload))
      ),
    { dispatch: false }
  );

  lockerLoginFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_FAIL,
          ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_FAIL
        ),
        tap(_ => {
          this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Token Login Failed',
              content: 'Your login token is either invalid or has expired.'
            }
          });
        })
      ),
    { dispatch: false }
  );

  openApplicationRegistrationAfterLockerLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.OPEN_APPLICATION_AFTER_LOCKER_LOGIN),
      mapToPayload,
      withLatestFrom(this.site.applications$),
      filter(
        ([loginInfo]) =>
          !!loginInfo.applicationGuid && !loginInfo.registrationId
      ),
      map(([loginInfo, applications]) => {
        const application = applications.find(
          app => app.guid === loginInfo.applicationGuid
        );
        return new fromSite.OpenRegistrationFormDialogAction({
          application
        });
      })
    )
  );

  signInUnregisteredStudent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.SIGN_IN_UNREGISTERED_STUDENT),
      mapToPayload,
      withLatestFrom(this.site.applications$),
      map(([loginInfo, applications]) => {
        if (loginInfo.applicationGuid) {
          const application = applications.find(
            app => app.guid === loginInfo.applicationGuid
          );
          return new fromSite.OpenRegistrationFormDialogAction({
            application
          });
        } else {
          return new fromSite.OpenStartApplicationDialogAction();
        }
      })
    )
  );

  openApplicationAfterLockerLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_APPLICATION_AFTER_LOCKER_LOGIN),
        mapToPayload,
        tap(loginInfo => {
          /**
           * Redirect user to application we got in locker
           */
          if (loginInfo.registrationId) {
            this.router.navigate(
              ['/dashboard', loginInfo.registrationId, 'welcome'],
              {
                queryParamsHandling: 'merge'
              }
            );
          } else {
            this.router.navigate(['/'], {
              queryParamsHandling: 'merge'
            });
          }
        })
      ),
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.FORGOT_PASSWORD_REQUEST),
      mapToPayload,
      switchMap(({ email, callback }) =>
        this.userApiService.forgotPassword(email, callback).pipe(
          map(_ => new fromAccount.ForgotPasswordSuccessAction({ email })),
          catchError(err =>
            of(new fromAccount.ForgotPasswordFailAction({ email, error: err }))
          )
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.RESET_PASSWORD_REQUEST),
      mapToPayload,
      switchMap(payload =>
        this.userApiService
          .updatePassword(payload.password, payload.callback)
          .pipe(
            map(_ => new fromAccount.ResetPasswordSuccessAction()),
            catchError(err => of(new fromAccount.ResetPasswordFailAction(err)))
          )
      )
    )
  );

  checkPasswordResetToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CHECK_PASSWORD_REST_TOKEN_REQUEST),
      mapToPayload,
      switchMap(payload =>
        this.userApiService.checkPasswordResetToken(payload).pipe(
          map(_ => new fromAccount.CheckPasswordResetTokenSuccessAction()),
          catchError(err =>
            of(new fromAccount.CheckPasswordResetTokenFailAction(err))
          )
        )
      )
    )
  );

  openSignInDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_SIGN_IN_DIALOG),
        mapToPayload,
        switchMap(payload => this.openSignInDialog(payload))
      ),
    { dispatch: false }
  );

  openSignInDialogWithSocialLoginError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_SIGN_IN_DIALOG_SOCIAL_LOGIN_ERROR),
        mapToPayload,
        switchMap(payload => this.openSignInDialog(payload))
      ),
    { dispatch: false }
  );

  openForgotPasswordDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_FORGOT_PASSWORD_DIALOG),
        mapToPayload,
        switchMap(payload => this.openForgotPasswordDialog(payload))
      ),
    { dispatch: false }
  );

  openCheckEmailDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAccount.OpenCheckEmailDialogAction>(
          ACCOUNT_ACTIONS.OPEN_CHECK_EMAIL_DIALOG
        ),
        mapToPayload,
        switchMap(({ email }) => this.openCheckEmailDialog(email))
      ),
    { dispatch: false }
  );

  openResetPasswordDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_RESET_PASSWORD_DIALOG),
        mapToPayload,
        switchMap(payload => this.checkPasswordResetToken(payload))
      ),
    { dispatch: false }
  );

  openResetPasswordConfirmationDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_RESET_PASSWORD_CONFIRMATION_DIALOG),
        switchMap(_ => this.openResetPasswordConfirmationDialog())
      ),
    { dispatch: false }
  );

  openChangePasswordDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.OPEN_CHANGE_PASSWORD_DIALOG),
        switchMap(_ => this.openChangePasswordDialog())
      ),
    { dispatch: false }
  );

  restoreSession$ = createEffect(() =>
    defer(() => {
      const cookieSession = this.documentRef.getCookie('app451-session');
      const cookieSession$ = of(cookieSession).pipe(truthy);
      const stateSession$ = this.accountService.authorization$.pipe(
        take(1),
        truthy
      );

      return race(cookieSession$, stateSession$).pipe(
        map(session => new fromAccount.LockerSessionLoginRequestAction(session))
      );
    })
  );

  openAccountExistsRegistrationDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ACCOUNT_ACTIONS.OPEN_ACCOUNT_EXISTS_REGISTRATION_DIALOG,
        ACCOUNT_ACTIONS.VALIDATE_USER_WITH_NULL_PASSWORD
      ),
      mapToPayload,
      switchMap(payload => this.openAccountExistsRegistrationDialog(payload))
    )
  );

  validateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.VALIDATE_USER_REQUEST),
      mapToPayload,
      withLatestFrom(this.accountService.isAuthorized$),
      concatMap(([payload, isAuthorized]) => {
        const { applicationGuid, userRegisterItem } = payload;
        // if the user is authorized, we can go straight to registration
        if (isAuthorized) {
          return of(new fromAccount.ValidateUserSuccessAction(payload));
          // if not, we need to check if the email is already in use
        } else {
          return this.userApiService
            .validateUser(
              applicationGuid,
              getValidationFieldsFromUserRegisterItem(userRegisterItem)
            )
            .pipe(
              responseData,
              map(_ => new fromAccount.ValidateUserSuccessAction(payload)),
              catchError(err => {
                if (err.error.data?.confirm_email) {
                  return of(
                    new fromAccount.ValidateUserWithNullPasswordAction({
                      ...payload,
                      confirm_email: true
                    })
                  );
                }
                return of(new fromAccount.ValidateUserFailAction(payload));
              })
            );
        }
      })
    )
  );

  validationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.VALIDATE_USER_FAIL),
      mapToPayload,
      map(
        payload =>
          new fromAccount.OpenAccountExistsRegistrationDialogAction(payload)
      )
    )
  );

  validationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.VALIDATE_USER_SUCCESS),
      mapToPayload,
      map(payload => new fromAccount.RegisterUserRequestAction(payload))
    )
  );

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.REGISTER_USER_REQUEST),
      mapToPayload,
      withLatestFrom(
        this.accountService.isAuthorized$,
        this.site.applications$
      ),
      exhaustMap(([payload, existingUser, applications]) => {
        const { applicationGuid, userRegisterItem } = payload;
        const application = applications.find(
          app => app.guid === applicationGuid
        );
        return this.userApiService
          .registerUser(
            applicationGuid,
            userRegisterItem as UserApi.UserRegisterItem,
            existingUser
          )
          .pipe(
            responseData,
            map(response => response.registration_id),
            map(
              registrationId =>
                new fromAccount.RegisterUserSuccessAction({
                  ...payload,
                  registrationId
                })
            ),
            catchError((response: HttpErrorResponse) => {
              const actionPayload: fromAccount.RegisterUserFailAction['payload'] =
                {
                  application,
                  errors: []
                };

              switch (response.status) {
                case 400:
                  actionPayload.errors = get(
                    response.error,
                    'data',
                    'info',
                    'errors'
                  ) || [response.error.userMessage];
                  break;

                case 500:
                default:
                  actionPayload.errors = [
                    this.transloco.translate(
                      'accountEffects.somethingWentWrong'
                    )
                  ];
                  break;
              }

              return of(new fromAccount.RegisterUserFailAction(actionPayload));
            })
          );
      })
    )
  );

  registerUserFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.REGISTER_USER_FAIL),
      mapToPayload,
      map(({ application, errors, formData }) => ({
        application,
        error: errors[0],
        formData
      })),
      map(payload => new fromSite.OpenRegistrationFormDialogAction(payload))
    )
  );

  registerUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.REGISTER_USER_SUCCESS),
        mapToPayload,
        withLatestFrom(this.accountService.isAuthorized$),
        tap(([payload, isAuthorized]) => {
          const credentials = mapFieldValuesToEmailAndPassword(
            payload.userRegisterItem.fields
          );
          if (!isAuthorized) {
            this.setPersistedSession(encodeAuth(credentials));
          }
          this.store.dispatch(
            new fromAccount.SignInAfterRegistrationAction({
              registrationId: payload.registrationId,
              ...credentials
            })
          );
        })
      ),
    { dispatch: false }
  );

  resendVerificationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.RESEND_VERIFICATION_CODE_REQUEST),
      mapToPayload,
      switchMap(payload =>
        this.userApiService.resendVerificationCode(payload.email).pipe(
          map(_ => new fromAccount.ResendVerificationCodeSuccessAction()),
          catchError(err =>
            of(new fromAccount.ResendVerificationCodeFailAction(err))
          )
        )
      )
    )
  );

  resendVerificationCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ACCOUNT_ACTIONS.RESEND_VERIFICATION_CODE_SUCCESS),
        tap(_ =>
          this.notifications.open({
            type: 'success',
            message: this.transloco.translate(
              'accountExistsRegistrationDialog.resendCodeSuccessMessage'
            )
          })
        )
      ),
    { dispatch: false }
  );

  confirmUserWithNoPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_REQUEST),
      mapToPayload,
      switchMap(payload => {
        const { item, code } = payload;
        const { applicationGuid, userRegisterItem } = item;

        return this.userApiService
          .confirmUserWithNoPassword(
            userRegisterItem as UserApi.UserRegisterItem,
            applicationGuid,
            code
          )
          .pipe(
            responseData,
            map(response => {
              return new fromAccount.ConfirmUserWithNoPasswordSuccessAction({
                token: response.token
              });
            }),
            catchError(err => {
              return of(
                new fromAccount.ConfirmUserWithNoPasswordFailAction(err)
              );
            })
          );
      })
    )
  );

  confirmUserWithNoPasswordFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_FAIL),
      mapToPayload,
      map(payload => {
        const { error } = payload;

        if (error?.data?.token && error?.data?.verified) {
          return new fromAccount.ConfirmUserWithNoPasswordSuccessAction({
            token: error.data.token,
            errorMessage: error.data.info?.errors[0]
          });
        }

        const errorMessage =
          error.data.message ||
          error.data.info?.errors[0] ||
          this.transloco.translate('accountEffects.somethingWentWrong');

        return new fromAccount.ApplicationError(errorMessage);
      })
    )
  );

  authUserAfterConfirmationWithLocker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CONFIRM_USER_WITH_NO_PASSWORD_SUCCESS),
      mapToPayload,
      switchMap(_data =>
        this.authApiService.loginLocker(_data.token).pipe(
          responseData,
          map(
            (data: ApplicationsApi.RequestLocker) =>
              new fromAccount.SignInSuccessAction({ token: _data.token, data })
          ),
          catchError(err => of(new fromAccount.SignInFailAction(err)))
        )
      )
    )
  );

  setOneTimeSession(session: string) {
    this.api451Client.authorization = session;
  }

  setPersistedSession(session: string) {
    this.api451Client.authorization = session;
    this.documentRef.setCookie('app451-session', session, 2);
    this.store.dispatch(new fromAccount.SetAuthTokenAction(session));
  }

  clearPersistedSession() {
    this.api451Client.authorization = null;
    this.documentRef.setCookie('app451-session', null, 0);
    this.store.dispatch(new fromAccount.ClearAuthTokenAction());
  }

  openSignInDialog(data: Partial<SignInDialogData> | null = null) {
    const component = this.dialog.openRef(SignInDialogComponent, {
      ...accountDialogConfig,
      data
    }).componentInstance;

    const signRequest$ = component.actions$.pipe(
      ofType(SIGN_IN_DIALOG_ACTIONS.SIGN_IN),
      mapToPayload,
      map(encodeAuth),
      tap(authentication => {
        this.store.dispatch(
          new fromAccount.SignInRequestAction({ authentication })
        );
      })
    );

    const resetRequest$ = component.actions$.pipe(
      ofType(SIGN_IN_DIALOG_ACTIONS.FORGOT_PASSWORD),
      tap(action => {
        component.dialogRef.close();
        this.store.dispatch(
          new fromAccount.OpenForgotPasswordDialogAction(action.payload)
        );
      })
    );

    const confirmMfaCode$ = component.actions$.pipe(
      ofType(SIGN_IN_DIALOG_ACTIONS.CONFIRM_MFA_CODE),
      mapToPayload,
      tap(({ code }) => {
        this.store.dispatch(
          new fromAccount.ConfirmMfaAuthLoginAction({ code })
        );
      })
    );

    const signSuccess$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.SIGN_IN_SUCCESS),
      tap(_ => component.dialogRef.close())
    );

    const signFail$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.SIGN_IN_FAIL),
      tap(
        _ =>
          (component.error = this.transloco.translate(
            'accountEffects.signInFail'
          ))
      )
    );

    return merge(
      signRequest$,
      signSuccess$,
      signFail$,
      resetRequest$,
      confirmMfaCode$
    ).pipe(takeUntil(component.dialogRef.afterClosed()));
  }

  openForgotPasswordDialog(data = null) {
    const component = this.dialog.openRef(ForgotPasswordDialogComponent, {
      ...accountDialogConfig,
      data
    }).componentInstance;

    const resetRequest$ = component.actions$.pipe(
      ofType(FORGOT_PASSWORD_DIALOG_ACTIONS.RESET_PASSWORD),
      mapToPayload,
      tap(email => {
        this.store.dispatch(
          new fromAccount.ForgotPasswordRequestAction({
            email,
            callback: callbackUrl()
          })
        );
      })
    );

    const returnToSignIn$ = component.actions$.pipe(
      ofType(FORGOT_PASSWORD_DIALOG_ACTIONS.RETURN_TO_SIGN_IN),
      mapToPayload,
      tap(payload => {
        component.dialogRef.close();
        this.store.dispatch(
          new fromAccount.OpenSignInDialogAction({ email: payload })
        );
      })
    );

    const resetSuccess$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.FORGOT_PASSWORD_SUCCESS),
      mapToPayload,
      tap(({ email }) => {
        component.dialogRef.close();
        this.store.dispatch(
          new fromAccount.OpenCheckEmailDialogAction({ email })
        );
      })
    );

    return merge(resetRequest$, returnToSignIn$, resetSuccess$).pipe(
      takeUntil(component.dialogRef.afterClosed())
    );
  }

  openCheckEmailDialog(email: string) {
    const component = this.dialog.openRef(CheckEmailDialogComponent, {
      ...accountDialogConfig,
      data: email
    }).componentInstance;

    const returnToSignIn$ = component.actions$.pipe(
      ofType(CHECK_EMAIL_DIALOG_ACTIONS.RETURN_TO_SIGN_IN),
      mapToPayload,
      tap(payload => {
        component.dialogRef.close();
        this.store.dispatch(
          new fromAccount.OpenSignInDialogAction({ email: payload })
        );
      })
    );

    const resetRequest$ = component.actions$.pipe(
      ofType(CHECK_EMAIL_DIALOG_ACTIONS.RESEND_PASSWORD),
      mapToPayload,
      tap(resendToEmail => {
        component.resending = true;
        this.store.dispatch(
          new fromAccount.ForgotPasswordRequestAction({
            email: resendToEmail,
            callback: callbackUrl()
          })
        );
      })
    );

    const resetSuccess$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.FORGOT_PASSWORD_SUCCESS),
      mapToPayload,
      tap(() => {
        component.resending = false;
        component.error = null;
      })
    );

    return merge(returnToSignIn$, resetRequest$, resetSuccess$).pipe(
      takeUntil(component.dialogRef.afterClosed())
    );
  }

  checkPasswordResetToken(token = null) {
    const checkSuccess$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CHECK_PASSWORD_REST_TOKEN_SUCCESS),
      tap(_ => this.setPersistedSession(token)),
      switchMap(_ => this.openResetPasswordDialog())
    );

    const checkFail$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.CHECK_PASSWORD_REST_TOKEN_FAIL),
      switchMap(() =>
        this.dialog
          .open(InvalidPasswordResetTokenDialogComponent, {
            ...accountDialogConfig,
            hasBackdrop: false
          })
          .pipe(switchMap(_ => this.openForgotPasswordDialog()))
      )
    );

    this.store.dispatch(
      new fromAccount.CheckPasswordResetTokenRequestAction(token)
    );

    return merge(checkSuccess$, checkFail$);
  }

  openResetPasswordDialog(data = null) {
    const component = this.dialog.openRef(ResetPasswordDialogComponent, {
      ...accountDialogConfig,
      data,
      hasBackdrop: false
    }).componentInstance;

    const submit$ = component.submit.pipe(
      tap(password =>
        this.store.dispatch(
          new fromAccount.ResetPasswordRequestAction({
            password,
            callback: callbackUrl()
          })
        )
      )
    );

    const resetSuccess$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.RESET_PASSWORD_SUCCESS),
      tap(_ => {
        component.error = null;
        component.dialogRef.close();
        this.store.dispatch(
          new fromAccount.OpenResetPasswordConfirmationDialogAction()
        );
      })
    );

    const resetFail$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.RESET_PASSWORD_FAIL),
      tap(
        _ =>
          (component.error = this.transloco.translate(
            'accountEffects.resetPasswordFail'
          ))
      )
    );

    return merge(submit$, resetSuccess$, resetFail$).pipe(
      takeUntil(component.dialogRef.afterClosed())
    );
  }

  openResetPasswordConfirmationDialog(data = null) {
    return this.dialog
      .open(ResetPasswordConfirmationDialogComponent, {
        ...accountDialogConfig,
        data,
        hasBackdrop: false
      })
      .pipe(switchMap(_ => this.openSignInDialog(null)));
  }

  openChangePasswordDialog(data = null) {
    const component = this.dialog.openRef(ResetPasswordDialogComponent, {
      ...accountDialogConfig,
      data,
      hasBackdrop: true
    }).componentInstance;

    const submit$ = component.submit.pipe(
      tap(password =>
        this.store.dispatch(
          new fromAccount.ResetPasswordRequestAction({
            password,
            callback: callbackUrl()
          })
        )
      )
    );

    const resetSuccess$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.RESET_PASSWORD_SUCCESS),
      tap(_ => {
        component.error = null;
        component.dialogRef.close();
      })
    );

    const resetFail$ = this.actions$.pipe(
      ofType(ACCOUNT_ACTIONS.RESET_PASSWORD_FAIL),
      tap(
        _ =>
          (component.error = this.transloco.translate(
            'accountEffects.resetPasswordFail'
          ))
      )
    );

    return merge(submit$, resetSuccess$, resetFail$).pipe(
      takeUntil(component.dialogRef.afterClosed())
    );
  }

  openAccountExistsRegistrationDialog(data) {
    return this.dialog
      .open(AccountExistsRegistrationDialogComponent, {
        autoFocus: false,
        data
      })
      .pipe(
        truthy,
        withLatestFrom(this.site.applications$),
        map(([response, applications]) => {
          const { userRegisterItem, applicationGuid, registrationType } =
            response.data;

          const email = getEmailFieldValue(userRegisterItem.fields).value;
          switch (response.action) {
            case 'back-to-registration': {
              const application = find(applications, {
                guid: applicationGuid
              });
              return new fromSite.OpenRegistrationFormDialogAction({
                registrationType,
                application,
                formData: mapFieldValuesToFieldWithData(userRegisterItem.fields)
              });
            }
            case 'reset-password':
              return new fromAccount.OpenForgotPasswordDialogAction(email);
            case 'login':
              return new fromAccount.OpenSignInDialogAction({
                email
              });
          }
        })
      );
  }

  private async _lockerLogin(
    {
      token,
      data
    }: {
      token: string;
      data: ApplicationsApi.RequestLocker;
    },
    options: { isUrlLocker?: boolean } = {}
  ) {
    const { loginInfo, id: userId } = data;

    // anonymous locker
    if (!userId) {
      this.store.dispatch(
        new fromAccount.OpenApplicationAfterLockerLoginAction(loginInfo)
      );
    } else {
      switch (loginInfo.usageScope) {
        case ApplicationsApi.LockerScope.Student:
          {
            this.setPersistedSession(token);
            this.store.dispatch(
              new fromUserApplications.LoadUserApplicationsRequestAction({
                userId
              })
            );

            /**
             * if student has not completed his registration make him complete it
             * before continuing to work with app
             */
            if (loginInfo.incompleteRegistration) {
              await this.router.navigate(['/'], {
                queryParamsHandling: 'merge'
              });
              this.store.dispatch(
                new fromAccount.SignInUnregisteredStudentAction(loginInfo)
              );
            } else if (options.isUrlLocker) {
              /**
               * only navigate if url locker is used
               */
              this.store.dispatch(
                new fromAccount.OpenApplicationAfterLockerLoginAction(loginInfo)
              );
            }
          }
          break;

        case ApplicationsApi.LockerScope.Recommender:
          {
            // we do not want recommender to have a long term session
            this.setOneTimeSession(token);
            this.router.navigate(['recommend', token], {
              queryParamsHandling: 'merge'
            });
          }
          break;
      }
    }
  }
}

function getValidationFieldsFromUserRegisterItem(
  userRegisterItem: Partial<UserApi.UserRegisterItem>
): Partial<UserApi.UserRegisterItem> {
  return {
    fields: [getEmailFieldValue(userRegisterItem.fields)]
  };
}

function getFieldValue(fields: FormsApi.FieldValue[], fieldName: string) {
  return find(fields, { name: fieldName });
}

function getEmailFieldValue(fields: FormsApi.FieldValue[]) {
  return getFieldValue(fields, 'email');
}

function getPasswordFieldValue(fields: FormsApi.FieldValue[]) {
  return getFieldValue(fields, 'password');
}

function mapFieldValuesToEmailAndPassword(fields: FormsApi.FieldValue[]) {
  return {
    email: getEmailFieldValue(fields)?.value,
    password: getPasswordFieldValue(fields)?.value
  };
}

function mapFieldValuesToFieldWithData(formData: FormsApi.FieldValue[]) {
  return formData.map(field => {
    return { key: field.name, value: field.value };
  }) as IFieldWithData[];
}
