import {
  Api451_Appointments_SearchAppointmentsQuery as SearchAppointmentsQuery,
  Api451_Appointments_SearchAppointmentsQueryVariables as SearchAppointmentsQueryVariables,
  AppointmentsSearchInput as AppointmentsSearchFilters
} from '@element451-libs/graphql-comet/client';
import { gql } from 'apollo-angular';

export {
  AppointmentsSearchFilters,
  SearchAppointmentsQuery,
  SearchAppointmentsQueryVariables
};

export const searchAppointmentsQuery = gql`
  query Api451_Appointments_SearchAppointments(
    $input: AppointmentsSearchInput!
  ) {
    countAppointments(input: $input)
    searchAppointments(input: $input) {
      guid
      public_uuid
      admin_id
      user_id
      start_date
      status
      duration
      availability_guid
      appointment_type
      location
      location_type
      fields
      notes {
        item_id
        body
        author_id
        created_at
        updated_at
      }
      updated_at
      created_at
    }
    appointmentsStats(input: $input) {
      overdue
      today
      upcoming
    }
    appointmentsDatesStats(input: $input) {
      today
      tomorrow
      thisWeek
      thisMonth
    }
  }
`;
