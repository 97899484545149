import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ApplicationCanBeRejectedDirective,
  ApplicationChecklistIncompleteDirective,
  ApplicationHasChecklistDirective,
  ApplicationHasDepositDirective,
  ApplicationHasSupplementalFormsDirective,
  ApplicationHasUserInfoRequestsDirective,
  ApplicationHasUserUploadsDirective,
  ApplicationInProgressDirective,
  ApplicationIsAdmittedDirective,
  ApplicationIsNotWithdrawnDirective,
  ApplicationIsWithdrawnDirective,
  ApplicationPaymentLeftDirective,
  ApplicationShowDocumentsSectionDirective,
  ApplicationShowStatusDirective,
  ApplicationStateDirective,
  ApplicationSubmitDirective,
  ApplicationSubmittedDirective
} from './application-state.directive';

@NgModule({
  imports: [CommonModule],
  exports: [
    ApplicationStateDirective,
    ApplicationSubmitDirective,
    ApplicationPaymentLeftDirective,
    ApplicationSubmittedDirective,
    ApplicationInProgressDirective,
    ApplicationChecklistIncompleteDirective,
    ApplicationHasChecklistDirective,
    ApplicationHasUserUploadsDirective,
    ApplicationHasUserInfoRequestsDirective,
    ApplicationIsAdmittedDirective,
    ApplicationHasDepositDirective,
    ApplicationIsWithdrawnDirective,
    ApplicationIsNotWithdrawnDirective,
    ApplicationCanBeRejectedDirective,
    ApplicationShowStatusDirective,
    ApplicationHasSupplementalFormsDirective,
    ApplicationShowDocumentsSectionDirective
  ],
  declarations: [
    ApplicationStateDirective,
    ApplicationPaymentLeftDirective,
    ApplicationSubmitDirective,
    ApplicationSubmittedDirective,
    ApplicationInProgressDirective,
    ApplicationChecklistIncompleteDirective,
    ApplicationHasChecklistDirective,
    ApplicationHasUserUploadsDirective,
    ApplicationHasUserInfoRequestsDirective,
    ApplicationIsAdmittedDirective,
    ApplicationHasDepositDirective,
    ApplicationIsWithdrawnDirective,
    ApplicationIsNotWithdrawnDirective,
    ApplicationCanBeRejectedDirective,
    ApplicationShowStatusDirective,
    ApplicationHasSupplementalFormsDirective,
    ApplicationShowDocumentsSectionDirective
  ]
})
export class ApplicationStateModule {}
