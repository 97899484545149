import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentsApi } from '@element451-libs/models451';
import {
  catchApolloError,
  mapApolloResponse
} from '@element451-libs/utils451/rxjs';
import { Apollo } from 'apollo-angular';
import { Observable, map, take } from 'rxjs';
import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse2 } from '../shared';
import {
  AppointmentsSearchFilters,
  GetAppointmentsPerAvailabilityCount,
  GetAppointmentsPerAvailabilityCountVariables,
  GetAppointmentsStatsQuery,
  GetAppointmentsStatsQueryVariables,
  GetAppointmentsTotal,
  GetAppointmentsTotalVariables,
  SearchAppointmentsQuery,
  SearchAppointmentsQueryVariables,
  getAppointmentsPerAvailabilityCount,
  getAppointmentsStatsQuery,
  getAppointmentsTotal,
  searchAppointmentsQuery
} from './queries';

type ExpandableAppointmentProperits = (keyof Pick<
  AppointmentsApi.Appointment,
  'admin_id'
>)[];

type R<T> = ElmResponse2<T>;

@Injectable()
export class AppointmentsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    private apollo: Apollo
  ) {}

  getAllForLoggedInUser(): Observable<R<AppointmentsApi.Appointment[]>>;
  getAllForLoggedInUser(
    expand: ExpandableAppointmentProperits
  ): Observable<R<AppointmentsApi.AppointmentPublic[]>>;
  getAllForLoggedInUser(expand?: ExpandableAppointmentProperits) {
    let params = new HttpParams();

    (expand || []).forEach(key => {
      params = params.has('expand[]')
        ? params.append('expand[]', key)
        : params.set('expand[]', key);
    });

    return this.http.get(this.url(`appointments/scheduled`), { params });
  }

  search(params: AppointmentsSearchFilters) {
    return this.apollo
      .query<SearchAppointmentsQuery, SearchAppointmentsQueryVariables>({
        query: searchAppointmentsQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            offset: params.offset,
            limit: params.limit,
            sort: params.sort,
            filters: params.filters || {}
          }
        }
      })
      .pipe(
        catchApolloError(result => result?.searchAppointments),
        map(({ data }) => ({
          searchAppointments: data.searchAppointments,
          countAppointments: data.countAppointments,
          appointmentsStats: data.appointmentsStats,
          appointmentsDatesStats: data.appointmentsDatesStats
        })),
        take(1)
      );
  }

  getStats(params: AppointmentsSearchFilters) {
    return this.apollo
      .query<GetAppointmentsStatsQuery, GetAppointmentsStatsQueryVariables>({
        query: getAppointmentsStatsQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            offset: params.offset,
            limit: params.limit,
            sort: params.sort,
            filters: params.filters || {}
          }
        }
      })
      .pipe(
        catchApolloError(result => result?.appointmentsStats),
        map(({ data }) => ({
          countAppointments: data.countAppointments,
          appointmentsStats: data.appointmentsStats,
          appointmentsDatesStats: data.appointmentsDatesStats
        })),
        take(1)
      );
  }

  getTotal() {
    return this.apollo
      .query<GetAppointmentsTotal, GetAppointmentsTotalVariables>({
        query: getAppointmentsTotal,
        fetchPolicy: 'network-only',
        variables: { input: {} }
      })
      .pipe(
        catchApolloError(result => result?.countAppointments),
        map(({ data }) => ({
          countAppointments: data.countAppointments,
          countUsersAppointments: data.countUsersAppointments
        })),
        take(1)
      );
  }

  create(appointment: AppointmentsApi.AppointmentDto) {
    return this.http.post<R<AppointmentsApi.Appointment>>(
      this.url(`appointments/scheduled`),
      appointment
    );
  }

  update(guid: string, appointment: Partial<AppointmentsApi.AppointmentDto>) {
    return this.http.put<R<AppointmentsApi.Appointment>>(
      this.url(`appointments/scheduled/${guid}`),
      appointment
    );
  }

  bulkUpdateStatus(guids: string[], status: AppointmentsApi.AppointmentStatus) {
    return this.http.put<R<AppointmentsApi.Appointment>>(
      this.url(`appointments/scheduled/bulk`),
      {
        appointments_guid: guids,
        status
      }
    );
  }

  delete(guid: string) {
    return this.http.delete<R<null>>(
      this.url(`appointments/scheduled/${guid}`)
    );
  }

  bulkDelete(guids: string[]) {
    return this.http.delete<R<boolean>>(
      this.url(`appointments/scheduled/bulk`),
      {
        body: { appointments_guid: guids }
      }
    );
  }

  countPerAvailability(availabilityGuid: string) {
    return this.apollo
      .query<
        GetAppointmentsPerAvailabilityCount,
        GetAppointmentsPerAvailabilityCountVariables
      >({
        query: getAppointmentsPerAvailabilityCount,
        fetchPolicy: 'network-only',
        variables: { input: { guid: availabilityGuid } }
      })
      .pipe(
        mapApolloResponse(
          result => result?.appointmentsPerAvailabilityCount || 0
        )
      );
  }
}
